/* stylelint-disable */

/**
 *  -----------------------------------------------
 * Layout styling (not only for Dashboard)
 *
 *  1. Wrappers
 *  2. Main nav
 *
 *  -----------------------------------------------
 */
 @import '../common.less';

/*
 * 1. Wrappers
 ****************************************************************/

// Used in:
//  - protected/modules/cms/views/default/index.php
//  - protected/modules/family/views/default/index.php
//  - protected/modules/patrol/views/onsite/layout.php
//  - protected/views/layouts/content_center_full.php
//  - protected/views/layouts/content_center_full_no_head.php
//  - protected/views/layouts/secondary.php
//  - protected/views/layouts/secondary_vue.php
//  - protected/views/layouts/top_nav.php
.wrapper {
  // margin: 0 15px !important;

  // Used for instance in /#/employee/staff/default/editinfo (My profile logged in as Staff role)
  .icon-email:before { //mail logo
    font-family: "alayacare-icon-font";
    content: "J";
    font-style: normal;
    vertical-align: text-bottom;
    line-height: 14px;
    margin-right: 10px;
    margin-top: 10px;
    font-weight: normal;
  }
}

// Used in protected/views/layouts/app.php
.ajaxbox-content{
  .pageNav{
    .whead {
      background-color: @content-top-color;
      li{
        a{
          color: #fff;
        }
        &:hover{
          background-color: @content-top-color-hover !important;
          a{
            color: @main-title-font-color;
          }
        }
        &.activeTab{
          background-color: @content-top-color-hover !important;
          a{
            color: @main-title-font-color;
          }
        }
      }
    }
  }
}

// Used connected as Client
.DashboardLeftSectionTab {
  border-radius: 3px;
  margin-top: 20px;
  position: relative;
  background-color: #fff;
  ul.tabs{
    background-color: @table-header-color;
    border-radius: 3px 3px 0 0;
    position: relative;
    li {
      border-right: 1px solid #fff;
      .activeTab {
        border-radius: 3px 0 0;
        background: @dashboard-table-active-tab;
      }
      a {
        color: #fff;
        padding: 0 10px;
      }
      &.tab-activity-feed{
        a:before{
          font-family: "alayacare-icon-font";
          content:"K";
          font-size:16px;
          padding-right: 5px;
          float: left;
          clear: left;
        }
      }
      &.tab-attendance{
        a:before{
          font-family: "alayacare-icon-font";
          content:"L";
          font-size:16px;
          padding-right: 5px;
          float: left;
          clear: left;
        }
      }
      &.tab-tickets{
        a:before{
          font-family: "alayacare-icon-font";
          content:"Q";
          font-size:16px;
          padding-right: 5px;
          float: left;
          clear: left;
        }
      }
      &.tab-journal{
        a:before{
          font-family: "alayacare-icon-font";
          content:"M";
          font-size:16px;
          padding-right: 5px;
          float: left;
          clear: left;
        }
      }
    }
  }
}

// KPIs (logged in as staff role only)
.dashboard {
  background: none repeat scroll 0 0 transparent;
  border: 0 solid transparent;
  box-shadow: 0 0 0 #fff;
  margin-top: 20px;
}

.DashboardLeftSectionTab ul.backupicon li.activeTab{
  background-color: @dashboard-table-active-tab;
}

table.dataTable {
  .clickable {
    cursor: pointer;
  }

  thead tr th:first-child {
    border-left: medium none;
    text-align: center;
  }
}
