
/* === Icomoon font icons === */

@font-face {
	font-family: 'IcoMoon';
	src: url('../fonts/icomoon.eot');
	src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
			url('../fonts/icomoon.svg#IcoMoon') format('svg'),
			url('../fonts/icomoon.woff') format('woff'),
			url('../fonts/icomoon.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* Add the following classes to your stylesheet if you want to use data attributes for inserting your icons */
.iconb::before, .icona::after { font-family: 'IcoMoon'; content: attr(data-icon); }
.whead .iconb { margin: 2px 0 0 0; display: block; float:left; }
.iconb, .iconb:hover { font-weight: normal; }

[class*='button'] .dropdown-menu li a [class*='icon'] { margin-left: 0; }

/* Add the following CSS properties to your stylesheet if you want to have a class per icon */
[class^='icon-'], [class*=' icon-'] { font-family: 'IcoMoon'; font-style: normal; vertical-align: middle; text-align: center; line-height: normal; margin-right: 5px; font-weight: normal; }
.dropdown-menu [class^='icon-'], .dropdown-menu [class*=' icon-'] { font-size: 12px; font-weight: normal; }

.icon-home::before { content: '\e000'; }
.icon-home-2::before { 	content: '\e001'; }
.icon-newspaper::before { content: '\e002'; }
.icon-pencil::before { content: '\e003'; }
.icon-pencil-2::before { content: '\e004'; }
.icon-picture::before { content: '\e005'; }
.icon-picture-2::before { content: '\e006'; }
.icon-camera::before { content: '\e007'; }
.icon-music::before { content: '\e008'; }
.icon-play::before { content: '\e009'; }
.icon-film::before { content: '\e00a'; }
.icon-camera-2::before { content: '\e00b'; }
.icon-spades::before { content: '\e00c'; }
.icon-clubs::before { content: '\e00d'; }
.icon-diamonds::before { content: '\e00e'; }
.icon-broadcast::before { content: '\e00f'; }
.icon-microphone::before { content: '\e010'; }
.icon-book::before { content: '\e011'; }
.icon-paper::before { content: '\e012'; }
.icon-paper-2::before { content: '\e013'; }
.icon-new::before { content: '\e014'; }
.icon-copy::before { content: '\e015'; }
.icon-folder::before { content: '\e016'; }
.icon-folder-2::before { content: '\e017'; }
.icon-tag::before { content: '\e018'; }
.icon-cart::before { content: '\e019'; }
.icon-basket::before { content: '\e01a'; }
.icon-calculate::before { content: '\e01b'; }
.icon-support::before { content: '\e01c'; }
.icon-phone::before { content: '\e01d'; }
.icon-mail::before { content: '\e01e'; }
.icon-location::before { content: '\e01f'; }
.icon-compass::before { content: '\e020'; }
.icon-history::before { content: '\e021'; }
.icon-clock::before { content: '\e022'; }
.icon-bell::before { content: '\e023'; }
.icon-bell-2::before { content: '\e024'; }
.icon-bell-3::before { content: '\e025'; }
.icon-calender::before { content: '\e026'; }
.icon-mouse::before { content: '\e027'; }
.icon-screen::before { content: '\e028'; }
.icon-laptop::before { content: '\e029'; }
.icon-mobile::before { content: '\e02a'; }
.icon-tablet::before { content: '\e02b'; }
.icon-mobile-2::before { content: '\e02c'; }
.icon-drawer::before { content: '\e02d'; }
.icon-drawer-2::before { content: '\e02e'; }
.icon-box-add::before { content: '\e02f'; }
.icon-box-remove::before { content: '\e030'; }
.icon-database::before { content: '\e031'; }
.icon-undo::before { content: '\e032'; }
.icon-redo::before { content: '\e033'; }
.icon-forward::before { content: '\e034'; }
.icon-reply::before { content: '\e035'; }
.icon-reply-2::before { content: '\e036'; }
.icon-comments::before { content: '\e037'; }
.icon-comments-2::before { content: '\e038'; }
.icon-comments-3::before { content: '\e039'; }
.icon-comments-4::before { content: '\e03a'; }
.icon-comments-5::before { content: '\e03b'; }
.icon-comments-6::before { content: '\e03c'; }
.icon-user::before { content: '\e03d'; }
.icon-user-2::before { content: '\e03e'; }
.icon-user-3::before { content: '\e03f'; }
.icon-busy::before { content: '\e040'; }
.icon-loading::before { content: '\e041'; }
.icon-loading-2::before { content: '\e042'; }
.icon-search::before { content: '\e043'; }
.icon-search-2::before { content: '\e044'; }
.icon-zoom-in::before { content: '\e045'; }
.icon-zoom-out::before { content: '\e046'; }
.icon-key::before { content: '\e047'; }
.icon-key-2::before { content: '\e048'; }
.icon-locked::before { content: '\e049'; }
.icon-unlocked::before { content: '\e04a'; }
.icon-wrench::before { content: '\e04b'; }
.icon-equalizer::before { content: '\e04c'; }
.icon-cog::before { content: '\e04d'; }
.icon-pie::before { content: '\e04e'; }
.icon-bars::before { content: '\e04f'; }
.icon-stats-up::before { content: '\e050'; }
.icon-gift::before { content: '\e051'; }
.icon-trophy::before { content: '\e052'; }
.icon-diamond::before { content: '\e053'; }
.icon-coffee::before { content: '\e054'; }
.icon-rocket::before { content: '\e055'; }
.icon-meter-slow::before { content: '\e056'; }
.icon-meter-medium::before { 	content: '\e057'; }
.icon-meter-fast::before { content: '\e058'; }
.icon-dashboard::before { content: '\e059'; }
.icon-fire::before { content: '\e05a'; }
.icon-lab::before { content: '\e05b'; }
.icon-remove::before { content: '\e05c'; }
.icon-remove-2::before { content: '\e05d'; }
.icon-remove-3::before { content: '\e05e'; }
.icon-briefcase::before { content: '\e05f'; }
.icon-briefcase-2::before { content: '\e060'; }
.icon-cars::before { content: '\e061'; }
.icon-bus::before { content: '\e062'; }
.icon-cube::before {  content: '\e063'; }
.icon-cube-2::before { content: '\e064'; }
.icon-puzzle::before { content: '\e065'; }
.icon-glasses::before { content: '\e066'; }
.icon-glasses-2::before { content: '\e067'; }
.icon-accessibility::before { content: '\e068'; }
.icon-accessibility-2::before { content: '\e069'; }
.icon-target::before { content: '\e06a'; }
.icon-target-2::before { content: '\e06b'; }
.icon-lightning::before { content: '\e06c'; }
.icon-power::before { content: '\e06d'; }
.icon-power-2::before { content: '\e06e'; }
.icon-clipboard::before { content: '\e06f'; }
.icon-clipboard-2::before { content: '\e070'; }
.icon-playlist::before { content: '\e071'; }
.icon-grid-view::before { content: '\e072'; }
.icon-tree-view::before { content: '\e073'; }
.icon-menu::before { content: '\e074'; }
.icon-menu-2::before { content: '\e075'; }
.icon-cloud::before { content: '\e076'; }
.icon-cloud-2::before { content: '\e077'; }
.icon-download::before { content: '\e078'; }
.icon-upload::before { content: '\e079'; }
.icon-upload-2::before { content: '\e07a'; }
.icon-link::before { content: '\e07b'; }
.icon-link-2::before { content: '\e07c'; }
.icon-flag::before { content: '\e07d'; }
.icon-flag-2::before { content: '\e07e'; }
.icon-flag-3::before { content: '\e07f'; }
.icon-eye::before { content: '\e080'; }
.icon-eye-2::before { content: '\e081'; }
.icon-bookmark::before { content: '\e082'; }
.icon-bookmark-2::before { content: '\e083'; }
.icon-star::before { content: '\e084'; }
.icon-star-2::before { content: '\e085'; }
.icon-star-3::before { content: '\e086'; }
.icon-heart::before { content: '\e087'; }
.icon-heart-2::before { content: '\e088'; }
.icon-thumbs-up::before { content: '\e089'; }
.icon-thumbs-down::before { content: '\e08a'; }
.icon-happy::before { content: '\e08b'; }
.icon-smiley::before { content: '\e08c'; }
.icon-neutral::before { content: '\e08d'; }
.icon-plus::before { content: '\e08e'; }
.icon-minus::before { content: '\e08f'; }
.icon-help::before { content: '\e090'; }
.icon-help-2::before { content: '\e091'; }
.icon-info::before { content: '\e092'; }
.icon-blocked::before { content: '\e093'; }
.icon-cancel::before { content: '\e094'; }
.icon-cancel-2::before { content: '\e095'; }
.icon-cancel-3::before { content: '\e096'; }
.icon-checkmark::before { content: '\e097';  }
.icon-minus-2::before { content: '\e098'; }
.icon-plus-2::before { content: '\e099'; }
.icon-enter::before { content: '\e09a'; }
.icon-exit::before { content: '\e09b'; }
.icon-loop::before { content: '\e09c'; }
.icon-arrow-up::before { content: '\e09d'; }
.icon-arrow-right::before { content: '\e09e'; }
.icon-arrow-down::before { content: '\e09f'; }
.icon-arrow-left::before { content: '\e0a0'; }
.icon-arrow-up-left::before { content: '\e0a1'; }
.icon-arrow-up-2::before { content: '\e0a2'; }
.icon-arrow-up-right::before { content: '\e0a3'; }
.icon-arrow-right-2::before { content: '\e0a4'; }
.icon-arrow-down-right::before { content: '\e0a5'; }
.icon-arrow-down-2::before { content: '\e0a6'; }
.icon-arrow-down-left::before {content: '\e0a7'; }
.icon-arrow-left-2::before { content: '\e0a8'; }
.icon-arrow-up-left-2::before { content: '\e0a9'; }
.icon-arrow-up-3::before { content: '\e0aa'; }
.icon-arrow-up-right-2::before { content: '\e0ab'; }
.icon-arrow-right-3::before { content: '\e0ac'; }
.icon-arrow-down-right-2::before { content: '\e0ad'; }
.icon-arrow-down-3::before { content: '\e0ae'; }
.icon-arrow-down-left-2::before { content: '\e0af'; }
.icon-arrow-left-3::before { content: '\e0b0'; }
.icon-arrow-up-left-3::before { content: '\e0b1'; }
.icon-arrow-up-4::before { content: '\e0b2'; }
.icon-arrow-up-right-3::before { content: '\e0b3'; }
.icon-arrow-right-4::before { content: '\e0b4'; }
.icon-arrow-down-right-3::before { content: '\e0b5'; }
.icon-arrow-down-4::before { content: '\e0b6'; }
.icon-arrow-down-left-3::before { content: '\e0b7'; }
.icon-arrow-left-4::before { content: '\e0b8'; }
.icon-arrow-up-5::before { content: '\e0b9'; }
.icon-arrow-right-5::before { content: '\e0ba'; }
.icon-arrow-down-5::before { content: '\e0bb'; }
.icon-arrow-left-5::before { content: '\e0bc'; }
.icon-arrow-up-6::before { content: '\e0bd'; }
.icon-arrow-right-6::before { content: '\e0be'; }
.icon-arrow-down-6::before { content: '\e0bf'; }
.icon-arrow-left-6::before { content: '\e0c0'; }
.icon-arrow-up-7::before { content: '\e0c1'; }
.icon-arrow-right-7::before { content: '\e0c2'; }
.icon-arrow-down-7::before { content: '\e0c3'; }
.icon-arrow-left-7::before { content: '\e0c4'; }
.icon-menu-3::before {  content: '\e0c5';}
.icon-enter-2::before { content: '\e0c6'; }
.icon-backspace::before { content: '\e0c7'; }
.icon-backspace-2::before { content: '\e0c8'; }
.icon-tab::before { content: '\e0c9'; }
.icon-tab-2::before { content: '\e0ca'; }
.icon-checkbox::before { content: '\e0cb'; }
.icon-checkbox-unchecked::before { content: '\e0cc'; }
.icon-checkbox-partial::before { content: '\e0cd'; }
.icon-radio-checked::before { content: '\e0ce'; }
.icon-radio-unchecked::before { content: '\e0cf'; }
.icon-font::before {  content: '\e0d0';}
.icon-paragraph-left::before { content: '\e0d1'; }
.icon-paragraph-center::before { content: '\e0d2'; }
.icon-paragraph-right::before { content: '\e0d3'; }
.icon-left-to-right::before { content: '\e0d4'; }
.icon-right-to-left::before { content: '\e0d5'; }
.icon-out::before { content: '\e0d6'; }
.icon-out-2::before { content: '\e0d7'; }
.icon-embed::before { content: '\e0d8'; }
.icon-seven-segment::before { content: '\e0d9'; }
.icon-seven-segment-2::before { content: '\e0da'; }
.icon-seven-segment-3::before { content: '\e0db'; }
.icon-seven-segment-4::before { content: '\e0dc'; }
.icon-seven-segment-5::before { content: '\e0dd'; }
.icon-seven-segment-6::before { content: '\e0de'; }
.icon-seven-segment-7::before { content: '\e0df'; }
.icon-seven-segment-8::before { content: '\e0e0'; }
.icon-seven-segment-9::before { content: '\e0e1'; }
.icon-seven-segment-10::before { content: '\e0e2'; }
.icon-bluetooth::before { content: '\e0e3'; }
.icon-share::before { content: '\e0e4'; }
.icon-share-2::before { content: '\e0e5'; }
.icon-mail-2::before { content: '\e0e6'; }
.icon-google-plus::before { content: '\e0e7'; }
.icon-google-plus-2::before { content: '\e0e8'; }
.icon-google-plus-3::before { content: '\e0e9'; }
.icon-facebook::before { content: '\e0ea'; }
.icon-facebook-2::before { content: '\e0eb'; }
.icon-twitter::before { content: '\e0ec'; }
.icon-twitter-2::before { content: '\e0ed'; }
.icon-twitter-3::before { content: '\e0ee'; }
.icon-twitter-4::before { content: '\e0ef'; }
.icon-feed::before { content: '\e0f0'; }
.icon-feed-2::before { content: '\e0f1'; }
.icon-youtube::before { content: '\e0f2'; }
.icon-youtube-2::before { content: '\e0f3'; }
.icon-vimeo::before { content: '\e0f4'; }
.icon-vimeo-2::before { content: '\e0f5'; }
.icon-flickr::before { content: '\e0f6'; }
.icon-flickr-2::before { content: '\e0f7'; }
.icon-picassa::before { content: '\e0f8'; }
.icon-picassa-2::before { content: '\e0f9'; }
.icon-dribbble::before { content: '\e0fa'; }
.icon-dribbble-2::before { content: '\e0fb'; }
.icon-forrst::before { content: '\e0fc'; }
.icon-forrst-2::before { content: '\e0fd'; }
.icon-deviantart::before { content: '\e0fe'; }
.icon-deviantart-2::before { content: '\e0ff'; }
.icon-github::before { content: '\e100'; }
.icon-github-2::before { content: '\e101'; }
.icon-github-3::before { content: '\e102'; }
.icon-github-4::before { content: '\e103'; }
.icon-wordpress::before { content: '\e104'; }
.icon-wordpress-2::before { content: '\e105'; }
.icon-blogger::before { content: '\e106'; }
.icon-blogger-2::before { content: '\e107'; }
.icon-tumblr::before { content: '\e108'; }
.icon-tumblr-2::before { content: '\e109'; }
.icon-yahoo::before { content: '\e10a'; }
.icon-yahoo-2::before { content: '\e10b'; }
.icon-amazon::before { content: '\e10c'; }
.icon-amazon-2::before { content: '\e10d'; }
.icon-apple::before { content: '\e10e'; }
.icon-android::before { content: '\e10f'; }
.icon-windows::before { content: '\e110'; }
.icon-soundcloud::before { content: '\e111'; }
.icon-soundcloud-2::before { content: '\e112'; }
.icon-skype::before { content: '\e113'; }
.icon-reddit::before { content: '\e114'; }
.icon-linkedin::before { content: '\e115'; }
.icon-lastfm::before { content: '\e116'; }
.icon-lastfm-2::before { content: '\e117'; }
.icon-delicious::before { content: '\e118'; }
.icon-stumbleupon::before { content: '\e119'; }
.icon-stumbleupon-2::before { content: '\e11a'; }
.icon-pinterest::before { 	content: '\e11b'; }
.icon-pinterest-2::before { content: '\e11c'; }
.icon-xing::before { content: '\e11d'; }
.icon-libreoffice::before { content: '\e11e'; }
.icon-file-pdf::before { content: '\e11f'; }
.icon-file-openoffice::before { content: '\e120'; }
.icon-file-word::before { content: '\e121'; }
.icon-file-excel::before { content: '\e122'; }
.icon-file-powerpoint::before { content: '\e123'; }
.icon-file-xml::before { content: '\e124'; }
.icon-file-css::before { content: '\e125'; }
.icon-html5::before { content: '\e126'; }
.icon-html5-2::before { content: '\e127'; }
.icon-css3::before { content: '\e128'; }
.icon-IcoMoon::before { content: '\e129'; }
.icon-home-3::before { content: '\e12a'; }
.icon-chat::before { content: '\e12b'; }
.icon-chat_alt_stroke::before { content: '\e12c'; }
.icon-chat_alt_fill::before { content: '\e12d'; }
.icon-comment_alt1_stroke::before { content: '\e12e'; }
.icon-comment_alt1_fill::before { content: '\e12f'; }
.icon-comment_stroke::before { content: '\e130'; }
.icon-comment_fill::before { content: '\e131'; }
.icon-comment_alt2_stroke::before { content: '\e132'; }
.icon-comment_alt2_fill::before { content: '\e133'; }
.icon-checkmark-2::before { content: '\e134'; }
.icon-check_alt::before { content: '\e135'; }
.icon-x::before { content: '\e136'; }
.icon-x_altx_alt::before { content: '\e137'; }
.icon-denied::before { content: '\e138'; }
.icon-cursor::before { content: '\e139'; }
.icon-rss::before { content: '\e13a'; }
.icon-rss_alt::before { content: '\e13b'; }
.icon-wrench-2::before { content: '\e13c'; }
.icon-dial::before { content: '\e13d'; }
.icon-calendar::before { content: '\e13e'; }
.icon-calendar_alt_stroke::before { content: '\e13f'; }
.icon-calendar_alt_fill::before { content: '\e140'; }
.icon-share-3::before { content: '\e141'; }
.icon-mail-3::before { content: '\e142'; }
.icon-heart_stroke::before { content: '\e143'; }
.icon-heart_fill::before { content: '\e144'; }
.icon-movie::before { content: '\e145'; }
.icon-document_alt_stroke::before { content: '\e146'; }
.icon-document_alt_fill::before { content: '\e147'; }
.icon-document_stroke::before { content: '\e148'; }
.icon-document_fill::before { content: '\e149'; }
.icon-plus-3::before { content: '\e14a'; }
.icon-plus_alt::before { content: '\e14b'; }
.icon-minus-3::before { content: '\e14c'; }
.icon-minus_alt::before { content: '\e14d'; }
.icon-pin::before { content: '\e14e'; }
.icon-link-3::before { content: '\e14f'; }
.icon-bolt::before { content: '\e150'; }
.icon-move::before { content: '\e151'; }
.icon-move_alt1::before { content: '\e152'; }
.icon-move_alt2::before { content: '\e153'; }
.icon-equalizer-2::before { content: '\e154'; }
.icon-award_fill::before { content: '\e155'; }
.icon-award_stroke::before { content: '\e156'; }
.icon-magnifying_glass::before { content: '\e157'; }
.icon-trash_stroke::before { content: '\e158'; }
.icon-trash_fill::before { content: '\e159'; }
.icon-beaker_alt::before { content: '\e15a'; }
.icon-beaker::before { content: '\e15b'; }
.icon-key_stroke::before { content: '\e15c'; }
.icon-key_fill::before { content: '\e15d'; }
.icon-new_window::before { content: '\e15e'; }
.icon-lightbulb::before { content: '\e15f'; }
.icon-spin_alt::before { content: '\e160'; }
.icon-spin::before { content: '\e161'; }
.icon-curved_arrow::before { content: '\e162'; }
.icon-undo-2::before { content: '\e163'; }
.icon-reload::before { content: '\e164'; }
.icon-reload_alt::before { content: '\e165'; }
.icon-loop-2::before { content: '\e166'; }
.icon-loop_alt1::before { content: '\e167'; }
.icon-loop_alt2::before { content: '\e168'; }
.icon-loop_alt3::before { content: '\e169'; }
.icon-loop_alt4::before { content: '\e16a'; }
.icon-transfer::before { content: '\e16b'; }
.icon-move_vertical::before { content: '\e16c'; }
.icon-move_vertical_alt1::before { content: '\e16d'; }
.icon-move_vertical_alt2::before { content: '\e16e'; }
.icon-move_horizontal::before { content: '\e16f'; }
.icon-move_horizontal_alt1::before { content: '\e170'; }
.icon-move_horizontal_alt2::before { content: '\e171'; }
.icon-arrow_left::before { content: '\e172'; }
.icon-arrow_left_alt1::before {  content: '\e173';}
.icon-arrow_left_alt2::before { content: '\e174'; }
.icon-arrow_right::before { content: '\e175'; }
.icon-arrow_right_alt1::before { content: '\e176'; }
.icon-arrow_right_alt2::before { content: '\e177'; }
.icon-arrow_up::before { content: '\e178'; }
.icon-arrow_up_alt1::before { content: '\e179'; }
.icon-arrow_up_alt2::before { content: '\e17a'; }
.icon-arrow_down::before { content: '\e17b';  }
.icon-arrow_down_alt1::before { content: '\e17c'; }
.icon-arrow_down_alt2::before { content: '\e17d'; }
.icon-cd::before { content: '\e17e'; }
.icon-steering_wheel::before { content: '\e17f'; }
.icon-microphone-2::before { content: '\e180'; }
.icon-headphones::before { content: '\e181'; }
.icon-volume::before { content: '\e182'; }
.icon-volume_mute::before { content: '\e183'; }
.icon-play-2::before { content: '\e184'; }
.icon-pause::before { content: '\e185'; }
.icon-stop::before { content: '\e186'; }
.icon-eject::before { content: '\e187'; }
.icon-first::before { content: '\e188'; }
.icon-last::before { content: '\e189'; }
.icon-play_alt::before { content: '\e18a'; }
.icon-fullscreen_exit::before { content: '\e18b'; }
.icon-fullscreen_exit_alt::before { content: '\e18c'; }
.icon-fullscreen::before { content: '\e18d'; }
.icon-fullscreen_alt::before { content: '\e18e'; }
.icon-iphone::before { content: '\e18f'; }
.icon-battery_empty::before { content: '\e190'; }
.icon-battery_half::before { content: '\e191'; }
.icon-battery_full::before { content: '\e192'; }
.icon-battery_charging::before { content: '\e193'; }
.icon-compass-2::before { content: '\e194'; }
.icon-box::before { content: '\e195'; }
.icon-folder_stroke::before {content: '\e196'; }
.icon-folder_fill::before { content: '\e197'; }
.icon-at::before { content: '\e198'; }
.icon-ampersand::before { content: '\e199'; }
.icon-info-2::before { content: '\e19a'; }
.icon-question_mark::before { content: '\e19b'; }
.icon-pilcrow::before { content: '\e19c'; }
.icon-hash::before { content: '\e19d'; }
.icon-left_quote::before { content: '\e19e'; }
.icon-right_quote::before { content: '\e19f'; }
.icon-left_quote_alt::before { content: '\e1a0'; }
.icon-right_quote_alt::before { content: '\e1a1'; }
.icon-article::before { content: '\e1a2'; }
.icon-read_more::before { content: '\e1a3'; }
.icon-list::before { content: '\e1a4'; }
.icon-list_nested::before { content: '\e1a5'; }
.icon-book-2::before { content: '\e1a6'; }
.icon-book_alt::before { content: '\e1a7'; }
.icon-book_alt2::before { content: '\e1a8'; }
.icon-pen::before { content: '\e1a9'; }
.icon-pen_alt_stroke::before { content: '\e1aa'; }
.icon-pen_alt_fill::before { content: '\e1ab'; }
.icon-pen_alt2::before { content: '\e1ac'; }
.icon-brush::before { content: '\e1ad'; }
.icon-brush_alt::before { content: '\e1ae'; }
.icon-eyedropper::before { content: '\e1af'; }
.icon-layers_alt::before { content: '\e1b0'; }
.icon-layers::before { content: '\e1b1'; }
.icon-image::before { content: '\e1b2'; }
.icon-camera-3::before { content: '\e1b3'; }
.icon-aperture::before { content: '\e1b4'; }
.icon-aperture_alt::before { content: '\e1b5'; }
.icon-chart::before { content: '\e1b6'; }
.icon-chart_alt::before { content: '\e1b7'; }
.icon-bars-2::before { content: '\e1b8'; }
.icon-bars_alt::before { content: '\e1b9'; }
.icon-eye-3::before { content: '\e1ba'; }
.icon-user-4::before { content: '\e1bb'; }
.icon-home-4::before { content: '\e1bc'; }
.icon-clock-2::before {content: '\e1bd'; }
.icon-lock_stroke::before { content: '\e1be'; }
.icon-lock_fill::before { content: '\e1bf'; }
.icon-unlock_stroke::before { content: '\e1c0'; }
.icon-unlock_fill::before { content: '\e1c1'; }
.icon-tag_stroke::before { content: '\e1c2'; }
.icon-tag_fill::before { content: '\e1c3'; }
.icon-sun_stroke::before { content: '\e1c4'; }
.icon-sun_fill::before { content: '\e1c5'; }
.icon-moon_stroke::before { content: '\e1c6';  }
.icon-moon_fill::before { content: '\e1c7'; }
.icon-cloud-3::before { content: '\e1c8'; }
.icon-rain::before { content: '\e1c9'; }
.icon-umbrella::before { content: '\e1ca'; }
.icon-star-4::before { content: '\e1cb'; }
.icon-map_pin_stroke::before { content: '\e1cc'; }
.icon-map_pin_fill::before { content: '\e1cd'; }
.icon-map_pin_alt::before { content: '\e1ce'; }
.icon-target-3::before { content: '\e1cf'; }
.icon-download-2::before { content: '\e1d0'; }
.icon-upload-3::before { content: '\e1d1'; }
.icon-cloud_download::before { content: '\e1d2'; }
.icon-cloud_upload::before { content: '\e1d3'; }
.icon-fork::before { content: '\e1d4'; }
.icon-paperclip::before { content: '\e1d5'; }
.icon-phone-2::before { content: '\e1d6'; }
.icon-mobile-3::before { content: '\e1d7'; }
.icon-mouse-2::before { content: '\e1d8'; }
.icon-address::before { content: '\e1d9'; }
.icon-email::before { content: '\e1da'; }
.icon-write::before { content: '\e1db'; }
.icon-attachment::before { content: '\e1dc'; }
.icon-reply-3::before { content: '\e1dd'; }
.icon-reply-to-all::before { content: '\e1de'; }
.icon-forward-2::before { content: '\e1df'; }
.icon-user-5::before { content: '\e1e0'; }
.icon-users::before { content: '\e1e1'; }
.icon-contact::before { content: '\e1e2'; }
.icon-card::before { content: '\e1e3'; }
.icon-export::before { content: '\e1e4'; }
.icon-location-2::before { content: '\e1e5'; }
.icon-map::before { content: '\e1e6'; }
.icon-compass-3::before { content: '\e1e7'; }
.icon-direction::before { content: '\e1e8'; }
.icon-center::before { content: '\e1e9'; }
.icon-share-4::before { content: '\e1ea'; }
.icon-heart-3::before { content: '\e1eb'; }
.icon-heart-4::before { content: '\e1ec'; }
.icon-star-5::before { content: '\e1ed'; }
.icon-star-6::before { content: '\e1ee'; }
.icon-thumbs-up-2::before { content: '\e1ef'; }
.icon-chat-2::before { content: '\e1f0'; }
.icon-comment::before { content: '\e1f1'; }
.icon-quote::before { content: '\e1f2'; }
.icon-printer::before { content: '\e1f3'; }
.icon-alert::before { content: '\e1f4'; }
.icon-link-4::before { content: '\e1f5'; }
.icon-flag-4::before { content: '\e1f6'; }
.icon-settings::before { content: '\e1f7'; }
.icon-search-3::before { content: '\e1f8'; }
.icon-trophy-2::before { content: '\e1f9'; }
.icon-price::before { content: '\e1fa'; }
.icon-camera-4::before { content: '\e1fb'; }
.icon-sleep::before { content: '\e1fc'; }
.icon-palette::before { content: '\e1fd'; }
.icon-leaf::before { content: '\e1fe'; }
.icon-music-2::before { content: '\e1ff'; }
.icon-shopping::before { content: '\e200'; }
.icon-flight::before { content: '\e201'; }
.icon-support-2::before { content: '\e202'; }
.icon-google-circles::before { content: '\e203'; }
.icon-eye-4::before { content: '\e204'; }
.icon-clock-3::before { content: '\e205'; }
.icon-microphone-3::before { content: '\e206'; }
.icon-calendar-2::before { content: '\e207'; }
.icon-flash::before { content: '\e208'; }
.icon-time::before { content: '\e209'; }
.icon-rss-2::before { content: '\e20a'; }
.icon-locked-2::before { content: '\e20b'; }
.icon-unlocked-2::before { content: '\e20c'; }
.icon-checkmark-3::before { content: '\e20d'; }
.icon-cancel-4::before { content: '\e20e'; }
.icon-minus-4::before { content: '\e20f'; }
.icon-plus-4::before { content: '\e210'; }
.icon-close::before { content: '\e211'; }
.icon-minus-5::before { content: '\e212'; }
.icon-plus-5::before { content: '\e213'; }
.icon-blocked-2::before { content: '\e214'; }
.icon-info-3::before { content: '\e215'; }
.icon-info-circle::before { content: '\e216'; }
.icon-help-3::before { content: '\e217'; }
.icon-help-4::before { content: '\e218'; }
.icon-warning::before { content: '\e219'; }
.icon-reload-CW::before { content: '\e21a'; }
.icon-reload-CCW::before { content: '\e21b'; }
.icon-shuffle::before { content: '\e21c'; }
.icon-back::before { content: '\e21d'; }
.icon-arrow::before { content: '\e21e'; }
.icon-retweet::before { content: '\e21f'; }
.icon-list-2::before { content: '\e220'; }
.icon-add::before { content: '\e221'; }
.icon-grid::before { 	content: '\e222'; }
.icon-document::before { content: '\e223'; }
.icon-document-2::before {  content: '\e224';}
.icon-documents::before { content: '\e225'; }
.icon-landscape::before { content: '\e226'; }
.icon-images::before { content: '\e227'; }
.icon-movie-2::before { content: '\e228'; }
.icon-song::before { content: '\e229'; }
.icon-folder-3::before { content: '\e22a'; }
.icon-archive::before { content: '\e22b'; }
.icon-trashcan::before { content: '\e22c'; }
.icon-upload-4::before { content: '\e22d'; }
.icon-download-3::before { content: '\e22e'; }
.icon-install::before { content: '\e22f'; }
.icon-cloud-4::before { 	content: '\e230'; }
.icon-upload-5::before { content: '\e231'; }
.icon-play-3::before { content: '\e232'; }
.icon-pause-2::before { content: '\e233'; }
.icon-record::before { content: '\e234'; }
.icon-stop-2::before { content: '\e235'; }
.icon-fast-forward::before { content: '\e236'; }
.icon-fast-backward::before { content: '\e237'; }
.icon-first-2::before { content: '\e238'; }
.icon-last-2::before { content: '\e239'; }
.icon-full-screen::before {  content: '\e23a';  }
.icon-collapse::before { content: '\e23b';  }
.icon-volume-2::before { content: '\e23c'; }
.icon-sound::before { content: '\e23d'; }
.icon-mute::before { content: '\e23e'; }
.icon-arrow-2::before { content: '\e23f'; }
.icon-arrow-3::before { content: '\e240'; }
.icon-arrow-4::before { content: '\e241'; }
.icon-arrow-5::before { content: '\e242'; }
.icon-arrow-6::before { content: '\e243'; }
.icon-arrow-7::before { content: '\e244'; }
.icon-arrow-8::before { content: '\e245'; }
.icon-arrow-9::before { content: '\e246'; }
.icon-arrow-10::before { content: '\e247'; }
.icon-arrow-11::before { content: '\e248'; }
.icon-arrow-12::before { content: '\e249'; }
.icon-arrow-13::before { content: '\e24a'; }
.icon-arrow-14::before { content: '\e24b'; }
.icon-arrow-15::before { content: '\e24c'; }
.icon-arrow-16::before { content: '\e24d'; }
.icon-arrow-17::before { content: '\e24e'; }
.icon-arrow-18::before { content: '\e24f'; }
.icon-arrow-19::before { content: '\e250'; }
.icon-arrow-20::before { content: '\e251'; }
.icon-arrow-21::before { content: '\e252'; }
.icon-triangle::before { content: '\e253'; }
.icon-triangle-2::before { content: '\e254'; }
.icon-triangle-3::before { content: '\e255'; }
.icon-triangle-4::before { content: '\e256'; }
.icon-code::before { content: '\e257'; }
.icon-battery::before { content: '\e258';  }
.icon-battery-2::before { content: '\e259'; }
.icon-battery-3::before { content: '\e25a';  }
.icon-battery-4::before { content: '\e25b'; }
.icon-battery-5::before { content: '\e25c'; }
.icon-history-2::before { content: '\e25d'; }
.icon-back-2::before { content: '\e25e'; }
.icon-sun::before { content: '\e25f'; }
.icon-sun-2::before { content: '\e260'; }
.icon-light-bulb::before { content: '\e261'; }
.icon-browser::before { content: '\e262'; }
.icon-publish::before {content: '\e263'; }
.icon-screen-2::before { content: '\e264'; }
.icon-arrow-22::before { content: '\e265'; }
.icon-broadcast-2::before { content: '\e266'; }
.icon-globe::before { content: '\e267'; }
.icon-square::before { content: '\e268'; }
.icon-inbox::before { content: '\e269'; }
.icon-network::before { content: '\e26a'; }
.icon-feather::before { content: '\e26b'; }
.icon-keyboard::before { content: '\e26c'; }
.icon-home-5::before { content: '\e26d'; }
.icon-bookmark-3::before { content: '\e26e'; }
.icon-book-3::before { content: '\e26f'; }
.icon-popup::before { content: '\e270'; }
.icon-search-4::before { content: '\e271'; }
.icon-dots-three::before { content: '\e272'; }
.icon-dots-two::before { content: '\e273'; }
.icon-dot::before { content: '\e274'; }
.icon-creative-commons::before { content: '\e275'; }
.icon-cd-2::before { content: '\e276'; }
.icon-suitcase::before { content: '\e277'; }
.icon-suitcase-2::before { content: '\e278'; }
.icon-droplet::before { content: '\e279'; }

/* === Icons (dark images) === */

[class^='icos-'], [class*='icos-'], [class='icos-'] { width: 14px; height: 14px; display: inline-block; margin-right: 10px; float: left; padding-top: 4px; }
.formRow label > [class*='icos-'] { padding-top: 6px; }
[class*='button'] > [class*='icos-']  { margin: 0; padding: 0; float:left;  display: block; }

.icos-trash::before { content: url('../../web/images/icons/usual/icon-trash.png'); }
.icos-search::before { content: url('../../web/images/icons/usual/icon-search.png'); }
.icos-add::before { content: url('../../web/images/icons/usual/icon-add.png'); }
.icos-pencil::before { content: url('../../web/images/icons/usual/icon-pencil.png'); }
