
@import '@alayacare/alaya-design-system/dist/system/tokens/tokens.custom-properties.css';

/* =====  CSS reset  ===== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td { 
    border: 0; 
    font-size: 100%; 
    margin: 0; 
    outline: 0; 
    padding: 0; 
    vertical-align: baseline; 
}
ul { list-style: none; }
blockquote, q { quotes: none; }
blockquote::before, blockquote::after,
q::before, q::after { 
    content: ''; 
    content: none; 
}

/* remember to define focus styles! */
:focus { outline: 0; }

/* remember to highlight inserts somehow! padding: 9px 17px 9px 17px; */
ins { text-decoration: none; }
del { text-decoration: line-through; }

/* tables still need 'cellspacing="0"' in the markup */
table { border-collapse: collapse; }
/* stylelint-disable-next-line selector-type-no-unknown */
*, * focus {
	margin: 0;
	outline: none;
	padding: 0;
}
textarea { overflow: auto; }
textarea, input, input[type='text'], input[type='password'] { 
    font-family: var(--webapp-font-family);
    font-size: 14px;
}

input, textarea { border: none; }

button, input[type='submit'] { 
    font-family: var(--webapp-font-family); 
}

img { border: 0; }
/* stylelint-disable-next-line no-duplicate-selectors */
ul { 
    list-style: none; 
    margin: 0; 
    padding: 0; 
}
p { 
    margin: 0; 
    padding: 0; 
}
/* stylelint-disable-next-line no-duplicate-selectors */
:focus { outline: 0; }
a { 
    color: var(--primary-700); 
    text-decoration: none; 
}

.normal { font-style: normal; }
.normalFont { 
    font-style: normal; 
    font-weight: normal; 
}


/* ==========  Correction classes ========== */
.mt40 { margin-top: 40px; }

.nomargin { margin: 0 !important; }

/* + Correction margins + */
.m10 { margin: 10px 0; }
.m15 { margin: 15px 0; }
.m20 { margin: 20px 0; }

.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.mr15 { margin-right: 15px; }
.mr20 { margin-right: 20px; }
.mr25 { margin-right: 25px; }
.mr30 { margin-right: 30px; }

.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb25 { margin-bottom: 25px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }


.mt0 { margin-top: 0 !important; }
.mt5 { margin-top: 5px; }
.mt8 { margin-top: 8px; }
.mt10 { margin-top: 10px; }
.mt12 { margin-top: 12px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt25 { margin-top: 25px; }
.mt30 { margin-top: 30px; }
.mt35 { margin-top: 35px; }


.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.ml15 { margin-left: 15px; }
.ml20 { margin-left: 20px; }
.ml25 { margin-left: 25px; }
.ml30 { margin-left: 30px; }

/* + Correction paddings + */
.pb0 { padding-bottom: 0 !important; }
.pb5 { padding-bottom: 5px; }
.pb10 { padding-bottom: 10px; }
.pb15 { padding-bottom: 15px; }
.pb20 { padding-bottom: 20px; }
.pb25 { padding-bottom: 25px; }
.pb30 { padding-bottom: 30px; }

.pt0 { padding-top: 0; }
.pt5 { padding-top: 5px; }
.pt10 { padding-top: 10px; }
.pt15 { padding-top: 15px; }
.pt20 { padding-top: 20px; }
.pt25 { padding-top: 25px; }
.pt30 { padding-top: 30px; }


input::-moz-focus-inner /*Remove button padding in FF*/
{
    border: 0;
    padding: 0;
}

button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

a.button::-moz-focus-inner /*Remove button padding in FF*/
{
    border: 0;
    padding: 0;
}
