// less/common.less is imported in web/js/vue-components/common/common.less

// Difference between general.less and common.less is:
// - general is loaded only in main.less
// - common is loaded in web/js/vue-components/common/common.less that is loaded in multiple Vue component

// WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Never add anything in this file except if absolute necessary as it will be imported in every instance of a Vue component
// WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

@import (reference) './utils/_mixins.less';
@import '~@alayacare/alaya-design-system/dist/system/tokens/tokens.less';

//Defaults
@white-arrow-down-select: '~web/images/white-arrow-bottom.png';
@grey-arrow-down-select: '~web/images/grey-arrow-bottom.png';

// Color name
// Colors are also declared in web/js/vue-components/common/colors.less
@dusty-gray: #999999;
@sapphire: #334fa3;
@silver: #cdcdcd;
@midnightblue: #054574;
@darkslate: #344676;
@tundora: #464646;
@cerulean: #008fdf;
@smalt-blue: #4f5f6f;
@orange: #ee6004;
@darkorange: #be5e00;
@dark-blue: #162a62;
@sap-green: #448510;
@dark-red: #8b0101;
@deep-red: #b90303;

@pop-over-font-color: @cerulean;
@pop-over-title-color: @midnightblue;

@text-font-color: #5f5f5f;
@left-navigation-color: @dark-blue;
@left-navigation-active-color: @cerulean;
@left-navigation-font-color: #fff;
@left-navigation-border-bottom-color: #227ed3;
@left-navigation-hover-color: #0066cc;
@top-wrapper: @orange;
@top-navigation-color: #004877;
@content-top-color: #5480a9;
@content-top-color-hover: #f0f3f6;
@main-title-font-color: @dark-blue;
@main-background-color: #f0f3f6;
@default-button-color: @cerulean;
@default-button-color-hover: #73b8f8;
@default-button-color-selected: #73b8f8;
@default-submit-button-color: #84b840;
@table-header-color: #004877;
@table-main-border-color: #70889f;
@table-stroke-color: #b9cad5;
@table-font-color: #004877;
@table-header-background-color: #5480a9;
@tr_hover_color: #f5fafe;
@tr_select_color: #e3f2ff;
@tr-odd-color: #f0f3f6;
@link_font_color: @cerulean;
@panel-header-color: #b9cad5;
@panel-font-color: #70889f;
@intake-status-label-color: @orange;
@blue-font-icon-color: @midnightblue;
@light-blue-font-icon-color: @cerulean;
@orange-font-icon-color: @orange;
@green-font-icon-color: green;
@red-font-icon-color: red;
@sorting-icon-color: #0387dd;
@paginator-background-color: @cerulean;
@active-paginator-background-color: @sapphire;
@filter-border-color: @cerulean;
@filter-font-color: #70889f;
@font_color_info:#227ed3;
@font_color_warning: #ffdc00;
@font_color_error: #c73939;
@font_color_success: #84b840;
@notification-font-color: #70889f;
@clinical-doc-label_color: #5480a9;
@clinical-doc-input-font-color: @cerulean;
@clinical-doc-button-font-color: @dark-blue;
@clinical-doc-button-border-color: #babec2;
@clinical-doc-button-background-color: #e3f0f6;
@clinical-doc-border: #cedbe9;
@care-plan-panel-header-background-color: #5480a9;
@input-border-color: #b9cad5;
@tabs_menu_background: #84b840;
@tabs_content_background: #e4e7e9;
@high-severity-risk-color: #ba1817;
@normal-severity-risk-color: #232F5E;
@no-risk-color: #e4e7e9;
@dashboard-table-active-tab: @cerulean;
@drop-down-option-color: #f0f3f6;
@drop-down-option-border-color: @cerulean;
@drop-down-color: @cerulean;
@drop-down-background-color: @cerulean;
@drop-down-color: @cerulean;
@modal-header-background: @shade-000;
@modal-header-font-color: @shade-700;
@modal-btn-close-background: #6f879e;
@modal-body-background: @color-white;
@dialog-label-font-color: @darkslate;
@select-disabled-font-color: #cccccc;
@select-disabled-background-color: #eeeeee;

@vitals-dashboard-red-tile: #db0000;
@vitals-high-alert: @deep-red;
@vitals-medium-alert: @orange;
@vitals-low-alert: #f3a536;
@vitals-normal-alert: #84b840;

@capacity-min-max-blue-text: #344575;
@progress-bar-background: #989ca0;

@progress-bar-green: #84b840;
@progress-bar-orange: @orange;
@progress-bar-red: #b80707;

@vacant-visit-zebra-color: #e4e7e9;

@modal-btn-close-background: #6f879e;

//shift content
@background-shift:  white;
@border-shift-blue: #4292b6;

@border-shift-vacant: @deep-red;
@border-shift-offered: @orange;
@border-shift-on-hold: #9FA9B7 ;
@border-shift-cancelled: #797979;
@border-shift-unavailability: #eea9ac;
@border-shift-replace: #162A62;
@border-shift-clockedin: #84B840;
@border-shift-late: #6543b3;
@border-shift-missed: #f5a623;
@border-shift-completed: #162A62;
@border-shift-approved: #386238;
@border-shift-duration: #baddf1;
@border-shift-recertification: @sapphire;

@background-unavailability-shift: #f8e6e6;
@calendar-border-color: #dddddd;

@font-shift: @dark-blue;

// Service Status
@status-background-color: white;
@status-background-color-active: @green-600;
@status-background-color-active-hover: @green-700;
@status-background-color-default: #babec2;
@status-background-color-discharged: @red-600;
@status-background-color-discharged-hover: @red-700;
@status-background-color-hold: @orange-600;
@status-background-color-hold-hover: @orange-700;
@status-background-color-needs-coordination: @bluegray-600;
@status-background-color-needs-coordination-hover: @bluegray-700;
@status-background-color-needs-scheduling: @blue-600;
@status-background-color-needs-scheduling-hover: @blue-700;
@status-background-color-needs-waiting-list: #5480a9;
@status-background-color-needs-waiting-list-hover: #1c4070;
@status-background-color-duration: #f0f8ff;
@status-background-color-recertification: #d7dced;
@status-color-title: #162A62;
@status-color-text-hover: #5480a9;

// Settings
@input-background-color: #fdfdfd;
@input-border-color: #d7d7d7;

// Datagrid
@tr-odd-background-color: #f9f9f9;
@tr-even-background-color: #eef1f5;
@tr-neutral-background-color: #f9f9f9;
@tr-drag-background-color: #eef1f5;

// Used in:
//  - vue-components/accounting/payor-invoicing/program-details/policies/split-percentage/payors-datagrid.vue
//  - protected/modules/staffing/views/scheduleCustomerEmployee/index.php
//  - protected/modules/scheduling/views/customer/index.php
.vertical-align-top {
  vertical-align: top;
}

.vue-tooltip,
.modal .modal-body .vue-tooltip {
  position: absolute;

  & .tooltip-inner {
    background-color: white;
    border: 1px solid @left-navigation-color;
    box-sizing: border-box;
    color: @left-navigation-color;
    font-size: 13px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    text-align: left;
    word-break: break-word;
  }

  &.in,
  &.fade.in {
    opacity: 1;
  }

  &.top > .tooltip-arrow {
    border-top-color: @left-navigation-color;
  }

  &.bottom > .tooltip-arrow {
    border-bottom-color: @left-navigation-color;
  }

  &.left > .tooltip-arrow {
    border-left-color: @left-navigation-color;
  }

  &.right > .tooltip-arrow {
    border-right-color: @left-navigation-color;
  }
}
