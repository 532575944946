/* stylelint-disable */

@import '~@alayacare/alaya-design-system/dist/system/tokens/tokens.less';
@import '~web/js/vue-components/common/colors.less';

.container-layout() {
  background: @color-white;
  border: 1px solid @shade-300;
  border-radius: 8px;
}

// Multiple usage in webapp and phpapp
.wrapper-with-breadcrumb {
  .col-xs-side-nav {
    width: 230px;
  }

  .col-xs-content {
    width: ~'calc(100% - 230px)';
  }

  .row {
    box-sizing: border-box;
    margin-left: -15px;
    margin-right: -15px;

    & > [class*=col] {
      box-sizing: border-box;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .breadcrumb-list {
    box-sizing: border-box;
    padding: 16px;
    .container-layout();
    margin-bottom: 0;
    width: auto;
    list-style: none;
    overflow: hidden;
    font-size: 18px;
    color: #162A62;

    li {
      display: inline-block;
      font-weight: 500;

      a {
        color: @link-color;
        cursor: pointer;
        pointer-events: auto;
      }

      div:first-child {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .breadcrumb-item, .breadcrumb-divider {
        line-height: 22px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }

  .widget {
    margin-top: 0;
  }

  .tab-content.widget {
    padding: 16px;
  }

  .wrapper {
    margin: 0 !important;
  }

  .new-data-grid .backgrid-paginator {
    height: auto;
    ul {
      margin-bottom: 0 !important;
    }
  }

  .fluid {
    .action-grid {
      width: auto;
    }

    .action-grid, .view-grid {
      margin-left: 0;
    }
  }

  ul.toolbar {
    margin-bottom: 0;
  }
}
