/* stylelint-disable */

:root {
    --dualListWidgetActivePaginationBtnColor: #4f5f6f;
    --dualListWidgetTableBorderColor: rgba(209,218,225,.5);
    --dualListWidgetTableHeaderBgColor: rgba(240,243,246,.8);
    --dualListWidgetTableColor: #162a62;
    --dualListWidgetTableBodyRowHoverBgColor: #eff4fe;
    --dualListWidgetTableBodyRowEvenBgColor: white;
    --dualListWidgetTableBodyRowOutdatedBgColor: var(--blue-100);
    --dualListWidgetPaginationBtnBorderColor: #d1dae1;
}

/* # Don't forget to remove necessary styles, if you don't use any of listed plugins
================================================== */

table.dataTable tbody tr td.tb{
    padding:0;
}

/* Autocomplete.js */
.guid-tag{
    position:relative;

}
.guid-tag input{display:none;}
.guid-tag {
    background: -moz-linear-gradient(center top , #FAFAFA 0%, #EEEEEE 100%) repeat scroll 0 0 transparent;
    border: 1px solid #DDDDDD;
    border-top: 1px solid #FDFDFD;
    display: block;
    font-size: 11px;
    padding: 4px 24px 4px 12px;
    position: relative;
}

.guid-tag .remove {
    background: url("../../web/images/elements/other/removeLi.png") no-repeat scroll 0 0 transparent;
    cursor: pointer;
    display: block;
    height: 8px;
    position: absolute;
    right: 10px;
    top: 11px;
    width: 7px;
}

/* ===== Datatables ===== */
table.dataTable {
    background-color: #fff;
    border: solid 1px var(--dualListWidgetTableBorderColor);
    clear: both;
    margin: 0 auto;
    width: 100%;
}
table.dataTable thead th {
    background-color: var(--dualListWidgetTableHeaderBgColor);
    border-bottom: solid 2px var(--dualListWidgetTableBorderColor);
    color: var(--dualListWidgetTableColor);
    cursor: pointer;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    height: 22px;
    line-height: 22px;
    padding: 8px 24px 8px 12px;
}
table.dataTable thead th.sorting:hover {
    border-bottom-color: var(--dualListWidgetTableColor);
}
table.dataTable tfoot th { padding: 3px 18px 3px 10px;font-weight: bold; }
table.dataTable td {
    height: auto;
    padding: .5rem;
    width: auto;
}
table.dataTable tbody tr td, table.dataTable thead tr th { vertical-align: middle;}
table.dataTable tbody tr:hover { background-color: var(--dualListWidgetTableBodyRowHoverBgColor) !important; }
table.dataTable tr.odd {  }
table.dataTable tr.even { background-color: var(--dualListWidgetTableBodyRowEvenBgColor); }
table.dataTable tr.outdated { background-color: var(--dualListWidgetTableBodyRowOutdatedBgColor); }

table.dataTable tr {
  border-top: 1px solid var(--shade-200);
  border-bottom: 1px solid var(--shade-200);
}

table.dataTable td.strong{font-size:1.05em}

table.dataTable a.ajaxtip{
    padding: 2px;
    font-size: 10px;
}

table.dataTable a.ajaxtip:hover{

opacity: 1;
}
tr.odd.gradeA td.sorting_1 { background-color: #f4f4f4; }
tr.odd.gradeA td.sorting_2 { background-color: #d1ffd1; }
tr.odd.gradeA td.sorting_3 { background-color: #d1ffd1; }
tr.even.gradeA td.sorting_1 { background-color: #efefef; }
tr.even.gradeA td.sorting_2 { background-color: #e2ffe2; }
tr.even.gradeA td.sorting_3 { background-color: #e2ffe2; }
tr.odd.gradeC td.sorting_1 { background-color: #c4c4ff; }
tr.odd.gradeC td.sorting_2 { background-color: #d1d1ff; }
tr.odd.gradeC td.sorting_3 { background-color: #d1d1ff; }
tr.even.gradeC td.sorting_1 { background-color: #d5d5ff; }
tr.even.gradeC td.sorting_2 { background-color: #e2e2ff; }
tr.even.gradeC td.sorting_3 { background-color: #e2e2ff; }

.dataTables_wrapper { position: relative; clear: both; }
.dataTables_length {
    color: var(--dualListWidgetTableColor);
    display: flex;
    font-size: 13px;
    margin: 0 10px;
}
.dataTables_length label {
    align-items: center;
    display: flex;
    margin-bottom: 0;
}
.dataTables_length .selector { margin: 0 6px; }
.showentries { float: left; margin: 4px 15px 0 0; display:none;  }
.dataTables_filter {
    float: left;
    font-size: 11px;
    text-align: left;
    position: relative;
}

.select2-selection--multiple,
.select2-selection,
.dataTables_filter input:not([type="button"]) {
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
}

.select2-selection--multiple:hover,
.select2-selection:hover,
.dataTables_filter input:not([type="button"]):hover {
    border-color: var(--primary-600) !important;
}

.dataTables_filter label:not(.vue-js-switch) > span { display: none; float: left; padding-top: 1px; }
.dataTables_filter select {margin-right: 10px;}
.dataTables_filter input[type=text] {
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--shade-400);
    border-radius: 6px;
    color: #4d586a;
    display: flex;
    font-family: var(--webapp-font-family);
    font-size: 1rem;
    height: 38px;
    padding: 4px 20px 5px 7px;
    position: relative;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    width: 150px;
}
.dataTables_filter input[type=text]:not(.disabled):focus-within {
    border-color: #1d5eed;
    border-color: var(--blue-600);
    box-shadow: 0 0 0 0.2rem #a0bbf7;
    box-shadow: 0 0 0 0.2rem var(--blue-300);
    outline: 0 none;
    outline-offset: 0;
  }
.dataTables_info {
    color: var(--dualListWidgetTableColor);
    display: flex;
    font-size: 13px;
    margin: 0 10px;
}
.tableFooter {
    align-items: center;
    background-color: #fff;
    border-top: none;
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    justify-content: space-between;
    min-height: 36px;
    padding: 14px 10px;
}
.tableFooter:after {content: none}
.tableFooter .dataTables_info { order: 1; }
.tableFooter .dataTables_length { order: 3; }
.tableFooter .dataTables_length,
.tableFooter .dataTables_info {
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  margin: 0;
  min-height: 36px;
}

.tableFooter .dataTables_paginate {
    flex: 999 0 320px;
    justify-content: center;
    order: 2;
    padding: 0 10px;
}

table.dataTable tr:hover{ background-color: #fff; }
.hiddenpars .tablePars { display: none; }
.shownpars .tablePars { display: block; }

.tablePars {
    clear: both;
    font-size: 12px;
    background-color : transparent;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.tablePars .button_set,
.tablePars input {
    align-self: flex-end;
}

.tablePars .floatR {
  flex-shrink: 0;
}

input.time_picker,
input.date_picker,
.dataTables_filter input[type=text].time_picker,
.dataTables_filter input[type=text].date_picker {
    background: white url('../../web/images/icons/usual/icon-dates.png') no-repeat;
    background-position: 95%;
    width: 110px;
}

.dataTables_filter .groupFilter-container,
.dataTables_filter div.selector,
.dataTables_filter div.period_chooser,
.dataTables_filter input[type=text],
.dataTables_filter input[type=button],
.dataTables_filter textarea {
    box-sizing: border-box;
    float: left;
    min-width: 120px;
}
.dataTables_filter .groupFilter-container:last-child,
.dataTables_filter div.selector:last-child,
.dataTables_filter div.period_chooser:last-child,
.dataTables_filter input[type=text]:last-child,
.dataTables_filter input[type=button]:last-child,
.dataTables_filter textarea:last-child {
    margin-right: 0;
}
.dataTables_filter .groupFilter-container {height: auto;}
.dataTables_filter > .groupFilter-container .btn-group .btn {
    border-radius: 2px;
}

.dataTables_filter div.period_chooser {
    border: none;
    margin: 0;
    padding: 0;
}
.dataTables_filter div.period_chooser div.selector,
.dataTables_filter div.selector select {
    margin-bottom: 0;
    margin-right: 0;
    line-height: 1;
    text-align: center;
}

.dataTables_filter div.selector select {
    border: none;
}

.dataTables_filter div.period_chooser tr:first-child,
.dataTables_filter div.period_chooser tr:last-child {
    display: flex;
    align-items: center;
    height: 100%;
}

.dataTables_filter div.period_chooser .arrow-left {margin-right: 5px;}
.dataTables_filter div.period_chooser .arrow-right {margin-left: 5px;}
.dataTables_filter div.period_chooser .arrow .icon-arrow-10 {line-height: 38px; margin: 0;}
.dataTables_filter div.period_chooser .arrow .icon-arrow-13 {line-height: 38px; margin: 0;}

.widget-v2 .dataTables_filter div.period_chooser .arrow .icon-arrow-10 {line-height: 38px;}
.widget-v2 .dataTables_filter div.period_chooser .arrow .icon-arrow-13 {line-height: 38px;}

.dataTables_filter div.period_chooser table td {vertical-align: top;}

.dataTables_filter input[type=button].tablectrl_small {
    margin-top: 0;
    font-size: 12px;
}

.dataTables_filter div.button_set {
    float: left;
    display: flex;
    gap: 8px;
}

.dataTables_filter input[type="button"].tablectrl_small {
    height: 32px;
}

.dataTables_paginate { display: flex; }
.dataTables_paginate .last { margin-right: 0!important; }

/* Two button pagination - previous / next */
.paginate_disabled_previous, .paginate_enabled_previous, .paginate_disabled_next, .paginate_enabled_next { height: 19px; float: left; cursor: pointer; color: #111 !important; }
.paginate_disabled_previous:hover, .paginate_enabled_previous:hover, .paginate_disabled_next:hover, .paginate_enabled_next:hover { text-decoration: none !important; }
.paginate_disabled_previous:active, .paginate_enabled_previous:active, .paginate_disabled_next:active, .paginate_enabled_next:active { outline: none; }
.paginate_disabled_previous, .paginate_disabled_next { color: #666 !important; }
.paginate_disabled_previous, .paginate_enabled_previous { padding-left: 23px; }
.paginate_disabled_next, .paginate_enabled_next { padding-right: 23px; margin-left: 10px; }
.paginate_enabled_previous { background: url('../../web/images/tables/back_enabled.png') no-repeat top left; }
.paginate_enabled_previous:hover { background: url('../../web/images/tables/back_enabled_hover.png') no-repeat top left; }
.paginate_disabled_previous { background: url('../../web/images/tables/back_disabled.png') no-repeat top left; }
.paginate_enabled_next { background: url('../../web/images/tables/forward_enabled.png') no-repeat top right; }
.paginate_enabled_next:hover { background: url('../../web/images/tables/forward_enabled_hover.png') no-repeat top right; }
.paginate_disabled_next { background: url('../../web/images/tables/forward_disabled.png') no-repeat top right; }

/* Full number pagination */
.paging_full_numbers { line-height: 22px; }
.paging_full_numbers span { display: flex; }
.paging_full_numbers .next:active, .paging_full_numbers .first:active, .paging_full_numbers .previous:active, .paging_full_numbers .last:active {
    font-weight: normal!important;
}
.paging_full_numbers a:active { outline: none }
.paging_full_numbers a:hover { text-decoration: none; }
.paging_full_numbers a.paginate_button, .paging_full_numbers a.paginate_active {
    background-color: #fff;
    border: 1px solid var(--dualListWidgetPaginationBtnBorderColor);
    border-radius: 3px;
    box-shadow: none;
    color: var(--dualListWidgetTableColor);
    font-size: 14px;
    font-weight: normal;
    margin: 4px;
    padding: 3px 10px;
    text-align: center;
}

.paging_full_numbers a.paginate_button:hover {
    border-color: var(--dualListWidgetActivePaginationBtnColor);
    box-shadow: 0 1px 4px 2px rgba(107, 133, 151, 0.2);
    text-decoration: none;
}
.paging_full_numbers a.paginate_active, .paging_full_numbers a.paginate_button:active {
    background-color: var(--dualListWidgetActivePaginationBtnColor);
    border-color: var(--dualListWidgetActivePaginationBtnColor);
    color: #fff;
}
.paginate_button_disabled, .paginate_button_disabled:active  {
    background-color: #eaeaea!important;
    border: none !important;
    color: #c5c5c5!important;
    cursor: default;
    box-shadow: none!important;
    font-weight: normal!important;
}
.paginate_button_disabled:active { border-color: #d3d3d3!important; }

.dataTables_processing { position: absolute; top: 50%; left: 50%; width: 250px; height: 30px; margin-left: -125px; margin-top: -15px; padding: 14px 0 2px 0; border: 1px solid #ddd; text-align: center; color: #999; font-size: 14px; background-color: white; }
.sorting { background: #eee url('../../web/images/tables/sort_both.png') no-repeat center right; }
.sorting_asc { background: #eee url('../../web/images/tables/sort_asc.png') no-repeat center right; }
.sorting_desc { background: #eee url('../../web/images/tables/sort_desc.png') no-repeat center right; }
.sorting_asc_disabled { background: url('../../web/images/tables/sort_asc_disabled.png') no-repeat center right; }
.sorting_desc_disabled { background: url('../../web/images/tables/sort_desc_disabled.png') no-repeat center right; }
table.dataTable th:active { outline: none; }
.dataTables_scroll { clear: both; }
.dataTables_scrollBody { -webkit-overflow-scrolling: touch; }


.widget-v2 .tToolbar [class*=" icon-"] {
  line-height: normal;
}
.widget-v2 .tablePars * {
  align-self: start;
}
.widget-v2 .dataTables_filter {
  z-index: 1;
}
.widget-v2 .tablePars .floatR {
  margin: 0 !important;
}
.widget-v2 .tablePars .dataTables_filterbtn-group a {
  height: 38px;
  box-sizing: border-box;
  padding: 9px 12px !important;
}
.widget-v2 .dual_list_widget table.dataTable {
  border: none;
}
.widget-v2 .dual_list_widget .dataTables_scroll,
.widget-v2 .dual_list_widget tbody {
  border-bottom: solid 1px var(--dualListWidgetTableBorderColor);
}
.widget-v2 .dual_list_widget .dataTables_scroll tbody {
  border-bottom: none;
}
.widget-v2 .dual_list_widget .dataTables_scrollHead {
  background-color: var(--dualListWidgetTableHeaderBgColor);
  border-bottom: solid 3px var(--dualListWidgetTableBorderColor);
  border-top: solid 1px var(--dualListWidgetTableBorderColor);
}
.widget-v2 .dual_list_widget .dataTables_scrollHead thead tr {
  border-bottom: none;
  border-top: none;
}
.widget-v2 .dual_list_widget .dataTables_scrollHead thead th {
  border-bottom: none;
}
.widget-v2 .dual_list_widget table.dataTable thead th {
  padding: .5rem 24px .5rem .5rem;
}
.widget-v2 .dual_list_widget table.dataTable tr:last-child {
  border-bottom: none;
}
.widget-v2 .dual_list_widget table.dataTable thead tr th:first-child {
  text-align: inherit;
}
.widget-v2 .dual_list_widget .dataTables_scrollBody thead tr {
  border: none;
}
.widget-v2 .dual_list_widget .dataTables_scrollBody tbody tr:first-child {
  border-top: none;
}
.widget-v2 .dual_list_widget .tableFooter {
  background-color: transparent;
}

/* CARE-1-PHP-primary-buttons: grid areas */

.care-1-php-primary-buttons {
  display: grid;
  gap: 1rem .5rem;
  grid-auto-flow: dense;
  grid-template-areas:
  "filter options toolbar"
  "widget widget widget";
  grid-template-columns: 1fr min-content min-content;
}

.care-1-php-primary-buttons:has(.whead) {
  grid-template-areas:
    "whead whead whead"
    "filter options toolbar"
    "widget widget widget";
}

.care-1-php-primary-buttons:has(.introduction) {
  grid-template-areas:
    "filter options toolbar"
    "intro intro intro"
    "widget widget widget";
}

.care-1-php-primary-buttons:has(.whead):has(.introduction) {
  grid-template-areas:
    "whead whead whead"
    "filter options toolbar"
    "intro intro intro"
    "widget widget widget";
}

.care-1-php-primary-buttons:has(.whead):not(:has(.tablePars)) {
  grid-template-areas:
    "whead . toolbar"
    "widget widget widget";
}

.care-1-php-primary-buttons.fluid:before {
  content: "";
  display: none;
}

.care-1-php-primary-buttons > * {
  grid-column-end: span 3;
}

.care-1-php-primary-buttons > .whead {
  grid-area: whead;
}

.care-1-php-primary-buttons > .introduction {
  grid-area: intro;
}

.care-1-php-primary-buttons > .tToolbar {
  grid-area: toolbar;
  padding: 0;
  white-space: nowrap;
}

/* Secondary Button Styles (default)  */

.care-1-php-primary-buttons > .tToolbar > ul > li > a {
  align-items: center;
  background: #eaedf0;
  border: 1px solid #eaedf0;
  border-radius: 6px;
  color: #181b21;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--webapp-font-family);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  padding: .5875rem 1rem;
  position: relative;
  text-align: center;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, -webkit-box-shadow .2s;
  user-select: none;
  vertical-align: bottom;
}

.care-1-php-primary-buttons > .tToolbar [class*=" icon-"],
.care-1-php-primary-buttons > .tToolbar [class^=icon-] {
  line-height: 1;
  margin-right: .5rem;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:focus {
  box-shadow: 0 0 0 .2rem #dfe2e7;
  outline: 0 none;
  outline-offset: 0;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:hover {
  background: #ced5dc;
  border-color: #ced5dc;
  color: #181b21;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:active {
  background: #b3bec9;
  border-color: #b3bec9;
  color: #181b21;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:has(.icon-plus-2):focus {
  box-shadow: 0 0 0 .2rem #a0bbf7;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:has(.icon-plus-2) {
  background: #1d5eed;
  border: 1px solid #1d5eed;
  color: #fff;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:has(.icon-plus-2):hover {
  background: #1251de;
  color: #fff;
  border-color: #1251de;
}

.care-1-php-primary-buttons > .tToolbar > ul > li > a:has(.icon-plus-2):active {
  background: #1048c5;
  color: #fff;
  border-color: #1048c5;
}

.care-1-php-primary-buttons .icon-plus-2 {
  font: normal normal normal 1rem/1 Phosphor
}

.care-1-php-primary-buttons .icon-plus-2:before {
  content: "\f531";
}

.care-1-php-primary-buttons > .tablePars {
  display: contents;
}

.care-1-php-primary-buttons > .tablePars > .dataTables_filter {
  grid-area: filter;
}

.care-1-php-primary-buttons > .tablePars > .options-dropdown {
  grid-area: options;
}

.care-1-php-primary-buttons > .tablePars .clear {
  display: none;
}

.care-1-php-primary-buttons > .dual_list_widget {
  grid-area: widget;
  padding-top: 0;
}

.care-1-php-primary-buttons .options-dropdown {
  margin: 0 !important;
  white-space: nowrap;
}

.care-1-php-primary-buttons .options-dropdown .tablectrl_small {
  padding: .5875rem 1rem !important;
}

.care-1-php-primary-buttons .options-dropdown .caret {
  border: 0;
  display: inline-flex;
  margin: 0 .5rem 0 0;
  width: auto;
  height: auto;
}

.care-1-php-primary-buttons .options-dropdown .caret:before {
  content: "\f31a";
  font: normal normal normal 1rem/1 Phosphor;
}

.care-1-php-primary-buttons .whead {
  align-items: center;
  color: #181b21;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0;
  width: 100%;
}

.care-1-php-primary-buttons .whead h6 {
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media screen and (max-width: 768px) {
  .care-1-php-primary-buttons {
    grid-template-areas:
      ". options toolbar"
      "filter filter filter"
      "widget widget widget";
  }

  .care-1-php-primary-buttons:has(.whead) {
    grid-template-areas:
      "whead whead whead"
      ". options toolbar"
      "filter filter filter"
      "widget widget widget";
  }

  .care-1-php-primary-buttons:has(.introduction) {
    grid-template-areas:
      ". options toolbar"
      "filter filter filter"
      "intro intro intro"
      "widget widget widget";
  }

  .care-1-php-primary-buttons:has(.whead):has(.introduction) {
    grid-template-areas:
      "whead whead whead"
      ". options toolbar"
      "filter filter filter"
      "intro intro intro"
      "widget widget widget";
  }

  .care-1-php-primary-buttons:has(.whead):not(:has(.tablePars)) {
    grid-template-areas:
      "whead . toolbar"
      "widget widget widget";
  }
}
