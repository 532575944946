@import '~@alayacare/alaya-design-system/dist/system/tokens/tokens.less';

/*
 * 2. Tabs header mixins
 ****************************************************************/

 :root {
  --acwa-tabs-header-padding: 0.6em;
  --acwa-tabs-header-height: 60; // We need to make some value unit less to do some maths
  --acwa-tabs-header-font-size: 24;
}

// Make reusable to override accessibility reset
.acwa-tabs-header-height() {
  height: calc(var(--acwa-tabs-header-height) / var(--acwa-tabs-header-font-size) * 1em); // force the height to prevent changing the height if icons has a different size
}

.acwa-tabs-header() {
  align-items: center;
  display: flex;
  font-size: calc(var(--acwa-tabs-header-font-size) * 1px);
  padding: 0 var(--acwa-tabs-header-padding);

  .acwa-tabs-header-height();

  @media only screen and (max-width: @breakpoint-md) {
    font-size: 20px;
  }

  @media only screen and (max-width: @breakpoint-sm) {
    font-size: 18px;
  }
}
