/* stylelint-disable */

/* WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*  Nothing should be added to this legacy file.
*  Use styles inside Vue components or in LESS files instead.
*  WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

// Disable autoprefixer for vendors that uses old gradient syntax and cause warning in the terminal
/* autoprefixer: off */
@import "../css/reset.css";
@import "../css/font.css";
@import "../css/ui_custom.css";
@import "../css/plugins.css";
@import "../../web/less/old_layout/dashboard.less";
@import "../js/vue-components/common/header-mixin.less";
@import '~@alayacare/alaya-design-system/dist/system/tokens/tokens.less';
@import '~web/less/layouts.less';

/* Table of Content
==================================================
        # Bootstrap 2.0 styles
        # General styles
        # Typography
        # Top part
        # Sidebar stuff
        # Content stuff
        # Form styles
        # Tables
        # Login page
        # Messages page
        # Special classes for resizable grid. Class "under" adds dynamically
        # Special classes for responsive layout
        # Media queries (remove them if you don't want to use responsive layout)
==================================================*/

/* # Bootstrap 2.0 styles
================================================== */

/*
* .label are colored blocks in DualListWidget
* .label-warning used in (not an exhaustive list):
*   - protected/modules/timekeeping/components/AccountTimeKeepingList.php
*   - Employee - Time Sheets and Premiums - Pay Periods
*   - Open in DualListWidget in /#/clients/<id>/visit-reports
*   - Visit Status in DualListWidget in /#/timekeeping/payroll/prepare/id/<id> Visit Premiums
*   - protected/modules/jobs/views/default/view.php
*   - web/js/vue-components/app-tools/api-status.vue:211
*   - http://demo3.localhost/#/ticket/admin/list Open
*   - http://demo3.localhost/#/accounting/billing/prepare/id/<id> Approve
*   - Visit Dialog - Replace - Continuity
*
*/

:root {
  --webapp-font-family: @font-legacy;
}

html.care-32-roboto-flex {
  --webapp-font-family: @font-default;
}

.label {
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 4px 6px;
  white-space: nowrap;
  background-color: #1d5eed;
}

a.label:hover { color: #ffffff; text-decoration: none; cursor: pointer; }
.label-important,
.label-important[href] {
  background-color: @danger-600;
}
.label-warning,
.label-warning[href] {
  background-color: @warning-500;
  color: @shade-800;
}
.label-success,
.label-success[href] {
  background-color: @success-600;
}
.label-info,
.label-info[href] {
  background-color: @info-600;
}
.label-inverse { background-color: #333333; }
.label-inverse[href] { background-color: #1a1a1a; }

.datatable-empty-state {
  background-color: @shade-100;
  color: @shade-800;
}

/* Patch to override .tToolbar styles in #/timekeeping/payroll/prepare/id/<id> Visit approval - Batch actions */
.tToolbar .dropdown-menu li {
  border-right: none;
}

.tToolbar .dropdown-menu li a:hover {
  background: #f5f5f5;
}

.modal.in .modal-dialog {
  transform: none !important;
}


/* # General styles
================================================== */

.ajaxtip td{padding:1px;}

.ux-div-loading{background:url('../../web/images/ajax-loader.gif') center no-repeat; padding:100px;}

.tCompress .dTable {
  font-size:0.9em;

  td {
    .filter span {
      font-family: var(--webapp-font-family);
    }
  }
}
.tCompress table.dataTable td{padding:0 3px}

.info{color:#ccc;}

.table_list tr td{margin:10px; font-size: 1.1em;}
.table_list tr td{padding:3px 10px;}
.table_list tr .label_cell{
    background:#eee;
    color:#666;
    font-weight: bold;
}
.table_list .label{display:block; text-align:center;}

html { height: 100%; }
body {
    font-family: var(--webapp-font-family);
    background-color: rgb(240, 243, 246);
    font-size: 14px;
    color: #5f5f5f;
    min-height: 100%;
    position: relative;
}

body#signin {
  background: @blue-800;
  height: 100%;
  min-height: auto; /* reset from body */
  width: 100%;
}

#page { padding-top: 55px;  }
#content { padding-bottom: 10px; position: relative; margin-left: 281px; padding-top: 40px; }

.under .wrapper{margin:0 10px;}
.clear { clear: both; display: block; }

.tablePars .clear {
  display: none;
}

.hidden { overflow:hidden }
.hide{display:none;}
.relative { position: relative; }
a { cursor: pointer; }
p { padding-top: 12px; }
img { border-radius: 2px; }
.inactive { color: #2B6893!important; }
.block{display:block!important;}
.strong{font-weight: bold;}

.display { display: block!important; }

.hand { cursor: pointer; }

.floatL { display: block; float: left; }
.floatR { display: block; float: right; }

/* jqueryFlot */
.legendLabel span { display: block; margin: 0 5px; }
.legendColorBox { padding-left: 10px; }

button, input[type=submit], input[type=image], input[type=reset] { cursor: pointer; }

/* bulleter in UI.php */
.bulleter_row{
    margin-bottom:10px;
}

.tooltip {
    border-radius: 2px;
    color: #fff;
    font-size: 11px;
    padding: 3px 8px;
    background: #3b3b3b;
    background: linear-gradient(to bottom, #3b3b3b 0%,#1d1d1d 100%);
}

/* DualList widget list-view */
.list-view-item .nav {
    display: none;
}

.list-view-item {
    padding: 10px;
    cursor: pointer;
    line-height: 16px;
}
.list-view-item img {
    float: left;
    width: 50px;
    margin-right: 10px;
}
.list-view-item ul.list-view-item-list {
    display: block;
    margin-left: 5px;
}

.list-view-item a {
    color: #333;
}

.list-view-item .head1 {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

span.void {
    text-decoration: line-through;
}

/* # Typography
================================================== */

/* === Headings === */

h1 { font-size: 24px; }
h2 { font-size: 22px; }
h3 { font-size: 20px; }
h4 { font-size: 18px; }
h5 { font-size: 16px; }
h6 { font-size: 14px; }

.w50{width:50px;}

/* === Text colors === */

.red { color: #A64949 !important; }
.green { color: #4D7F12; }
.grey { color: #aaa; }

/* === Placeholder color === */

::-webkit-input-placeholder { color: #b3b3b3; }
:-moz-placeholder { color: #b3b3b3; }

/* === Text alignment === */

.textL { text-align: left!important; }
.textC { text-align: center; }
.textR { text-align: right; }

/* === Label styles === */

.dynformfield.sending{
    border-color:yellow;
}
/* === List styles === */

.widget ol { padding-left: 16px; }
.caption { display: block; font-weight: bold; padding-bottom: 4px; }

/* # Top part
================================================== */

.logo { float: left; margin-top: 2px; margin-left: -15px; }

/* === User (top left of sidebar) === */

.user { width: 100%; text-align: center; position: relative; padding-top: 16px; padding-bottom: 10px; background-color: rgb(0, 143, 223); }
.user a { position: relative; display: inline-block; }
.user a > img { border-radius: 50%; }
.user a > span {  padding: 0; position: absolute; left: 65px; top: 1px; display: block; text-align: center; border-bottom-left-radius: 2px; }
.user a > span > strong {
  font-size: 11px;
  font-weight: bold;
  display: block;
  padding: 0 5px;
  color: white;
  line-height: 17px;
  height: 16px;
  white-space: nowrap;
  border-radius: 2px;
  background: #DA8854;
  background: linear-gradient(to bottom, #DA8854 0%,#C35A32 100%);
}
.user > span {
  display: block;
  color: #c2c2c2;
  margin-top: 10px;
}

/* === Subnav styles === */

.subNav { border-top: 1px solid #c3c3c3; border-bottom: 1px solid #c3c3c3; }
.subNav .hasNum { padding: 7px 45px 8px 14px; }
.subNav li { font-size: 1.1em; position: relative;  }
.subNav li a > img { display: block; float: left; padding: 5px 10px 0 0; }
.subNav li a { color: #6a6a6a; padding: 8px 14px 8px 14px; display: block;  border-top: 1px solid #fdfdfd; border-bottom: 1px solid #cecece; font-weight: bold;
    background: #f7f7f7;
    background: linear-gradient(to bottom,  #f7f7f7 0%,#e6e6e6 100%);
}
.subNav li a:hover {
    background: #fbfbfb;
    background: linear-gradient(to bottom,  #fbfbfb 0%,#e8e8e8 100%);
}
.subNav li a:active { background: #f0f0f0; }
.subNav li ul { border-bottom:1px solid #cecece; }
.subNav li ul li { border-top:1px solid #e3e3e3; }
.subNav li ul li:first-child { border-top: none; }
.subNav li ul li a { background: #fafafa url('../../web/images/elements/control/subnav_arrow.png') no-repeat 14px; padding-left: 30px; border: none; font-weight: normal; }
.subNav li ul li a:hover { background: #fffffa url('../../web/images/elements/control/subnav_arrow.png') no-repeat 14px; }
.subNav li a.this {
  background: url('../../web/images/backgrounds/body.jpg');
  margin-right: -1px;
  color: #a64949;
  padding-left: 10px;
}

/* === Sidebar widgets === */

/* Numbers statistics (inside protected/modules/accounting/models/StatHelper.php) */

.numStats ul li { float: left; text-align: center; width: 50px; padding: 0 12px; border-right: 1px dotted #bbb; border-left: 1px dotted #f5f5f5; }
.numStats ul li a { color: #b35d5d; text-shadow: 0 1px #f5f5f5; font-size: 18px; font-weight: bold; }
.numStats ul li span { display: block; color: #717e88; font-size: 11px; margin-top: -4px; }
.numStats ul li.last { border-right: none; }
.numStats ul li:first-child { border-left: none; }

/* Status of current user in top left sidebar (green dot when available) */
.status_available {
  width: 7px;
  height: 7px;
  background: url('../../web/images/elements/other/contact_status.png') no-repeat;
  background-position: 0 0;
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -4px;
}

.verticalTab {
  color: @shade-700;
  list-style: none;
  font-size: @font-size-sm;
  font-weight: 400;
}

.elastic-list {
    padding: @space-sm 0;


    @media (min-width: @breakpoint-md) {
      max-width: 320px;
      margin-bottom: 0;
    }
}

.care-13-agency-contacts-dialog .elastic-list {
  margin-bottom: 1rem;

  @media (min-width: @breakpoint-md) {
    margin-bottom: 0;
  }
}

ul.verticalTab {
    background-color: @color-white;
    margin: 0;
}

.elastic-list .caret {
    border: none;
    position: absolute;
    right: 30px;
}

.elastic-list .caret::before {
    content: "\f31a";
  display: inline-block;
  font: normal normal normal 1em/1 'Phosphor';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* PHP vertical tabs */
.verticalTab [class*="dataNum"]{
    position: absolute!important;
    right: 3px!important;
    top: 0!important;
}

.verticalTab > li {
    display: block;
    position: relative;
}

.verticalTab > li.active {
    background: @highlight-bg !important;
}

.elastic-list-selected-label a {
    color: @shade-700;
}

.verticalTab > li a {
    text-decoration: none;
    color: @shade-700;
    display: block;
    height: 100%;
    padding: 0.75rem 1rem;
}

.verticalTab > li:hover {
    background: @shade-200;
}

.verticalTab > li.disabled a {
    cursor: default;
}


/* # Content stuff
================================================== */

/* === Dividers === */

.divider { height: 0; border-top: 1px solid #cdcdcd; border-bottom: 1px solid #fff; position: relative; margin: 35px 0 0 0; }
.divider > span { position: absolute; left: 50%; margin-left: -21px; background: #eeeeee url('../../web/images/div.png') no-repeat 50%; width: 36px; padding: 0 3px; height: 9px; display: block; top: -4px; }

.widget .divider { height: 0; border-top: 1px solid #dfdfdf; border-bottom: 1px solid #fff; position: relative; margin: 20px 0; min-height: 0; }
.widget .divider > span { position: absolute; left: 50%; margin-left: -21px; background: #eeeeee url('../../web/images/widgetDiv.png') no-repeat 50%; width: 36px; padding: 0 3px; height: 9px; display: block; top: -4px; }

.dialog .divider { height: 0; border-top: 1px solid #cdcdcd; border-bottom: 1px solid #fff; position: relative; margin: 20px 0; }
.dialog .divider > span { position: absolute; left: 50%; margin-left: -21px; background: #f5f5f5 url('../../web/images/div.png') no-repeat 50%; width: 36px; padding: 0 3px; height: 9px; display: block; top: -4px;  }

/* === Color marks === */

[class*="dataNum"] { border-radius: 2px; color: #fff!important; font-size: 11px; font-weight: bold; display: block; padding: 3px 6px 2px 6px; height: 15px; line-height: 15px; }
#sidebar [class*="dataNum"] { position: absolute; right: 8px; top: 10px; }
#content [class*="dataNum"] { position: relative; text-shadow: none; display: inline-block; float: right; margin: 0; }
.dataNumBlue { background: #6F93A6; box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #607f90; }
.dataNumGrey { background: #767676; box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #666666; }
.dataNumGreen { background: #7aa745; box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #6a913b; }
.dataNumRed { background: #b35d5d; box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #9b5151; }

/* === Content top part === */

.branch_description {font-size: 12px;}

/* Breadcrumbs */

.bc { margin: 0; float: left; }
ul.breadcrumbs { position: relative; z-index: 990; margin-left: 18px; }
ul.breadcrumbs li { float: left; }
ul.breadcrumbs li.current { border-right: none; }
ul.breadcrumbs li a { color: #666666; text-decoration: none; padding: 3px 20px 3px 14px; display: block; background: url('../../web/images/icons/breadsArrow.png') no-repeat 100% 10px; height: 22px; line-height: 23px; }
ul.breadcrumbs li a:hover, ul.breadcrumbs li.hover a { color: #2B6893; }
ul.breadcrumbs > li:first-child { background: url('../../web/images/icons/breadsHome.png') no-repeat 12px 10px; }
ul.breadcrumbs > li:first-child > a { padding-left: 28px; }
ul.breadcrumbs li.current > a { color: #B1B1B1; background: none; }
ul.breadcrumbs li ul { position: absolute; background: url('../../web/images/subArrow.png') no-repeat 10px 5px; font-size: 11px; width: 160px; top: 28px; padding-top: 10px; margin-left: 12px; display: none; }
ul.breadcrumbs li ul li { width: 180px; border-bottom: 1px solid #343434; text-align: left; border-top: 1px solid #545454; background: url('../../web/images/backgrounds/sidebar.jpg'); padding: 0; float: none; }
ul.breadcrumbs li ul li:first-child { border-top-right-radius: 2px; border-top-left-radius: 2px; border-top: none; padding-top: 1px; }
ul.breadcrumbs li ul li:last-child { border-bottom-right-radius: 2px; border-bottom-left-radius: 2px; }
ul.breadcrumbs li ul li a {
  text-decoration: none;
  padding: 5px 14px 5px 24px;
  color: #CCCCCC !important;
  display: block;
  background: url('../../web/images/elements/control/subnav_arrow.png') no-repeat 12px 13px;
}
ul.breadcrumbs li ul li a:hover { background-color: #454545 }

/* === Middle navigation buttons === */
.middleNavA li, .middleNavA li a { border-radius: 3px; }
.middleNavA li { box-shadow: none; }
.middleNavA li a { box-shadow: none; }
.middleNavA { text-align: center; margin: 30px 0 0 0; }
.middleNavA li { margin: 5px 10px 10px 10px; position: relative; display: inline-block; text-align: center; }
.middleNavA li a {
    min-width: 70px;
    background: #f7f7f7;
    background: linear-gradient(to bottom,  #f7f7f7 0%,#efefef 100%);
    border: 1px solid #d5d5d5;
    box-shadow: 0 0 0 1px #fcfcfc inset, 0 1px 1px #d5d5d5;
    padding: 10px 16px 2px 16px;
    display: block;
    font-weight: bold;
    white-space: nowrap;
    color: #626262;
}
.middleNavA li a:hover {
    background: #f7f7f7;
    background: linear-gradient(to bottom,  #f7f7f7 0%,#f2f2f2 100%);
}
.middleNavA li a:active {
    box-shadow: none;
    background: #f4f4f4;
    background: linear-gradient(to bottom,  #f4f4f4 0%,#f7f7f7 100%);
}
.middleNavA li a > span { display: block; padding-top: 4px; font-weight: bold; }
.middleNavA li a > .iconb { font-size: 32px; color: #808080; }

[class*="middleNav"] li strong {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 11px;
  color: #fff;
  padding: 3px 5px;
  display: block;
  line-height: 12px;
  border-radius: 2px;
  background: #ba6d6d;
  background: linear-gradient(to bottom, #ba6d6d 0%,#934848 100%);
}

/* === Widget and widget head elements === */

.legacy-content .widget {
    .container-layout();

    color: @shade-700;
    position: relative;
    padding: 16px;
}

.legacy-content .widget + .widget:not([class*=grid]) {
    margin-top: 16px;
}


.widget > [class*="whead"] {
  align-items: center;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  box-sizing: border-box;
  color: @shade-900;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 @space-xxl ;
  width: 100%;

  .clear {
    display: none;
  }
}

// Tabs restyled to look like AcSelectButton
.widget {
  .tabs.whead {
    justify-content: start;

    li {
      border: 1px solid #ced2da;
      box-sizing: border-box;
      color: @bluegray-700;

      transition: background-color .2s, color .2s, border-color .2s;

      &.activeTab {
        background-color: @blue-600;
        border-color: @blue-600;

        a {
          color: @color-white;
        }
      }
      &:not(:last-child) {
        border-right: 0;
      }

      &:hover:not(.activeTab) {
        background-color: @shade-200;
        border-color: @shade-400;
      }

      a {
        color: @shade-700;
      }
    }

    li:first-child {
      border-radius: 6px 0 0 6px;
    }

    li:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}

.widget > [class*="whead"] h6 {
    font-size: @font-size-xl;
    font-weight: var(--font-weight-bold);
    margin-top: @space-md;
    margin-bottom: @space-md;
}

.widget .body { padding: 14px 16px; }

/* Form 1.0 */
.form-section{
    color: #636363;
    background-color: #e4e7e9;
    font-weight: bold;
}
/* Quick workaround to make a section label take all width */
.fluid .form-section .grid4{
    width: 100%;
}

/* Head icons and 1 */

.whead .iconb { font-size: 16px; }
.whead [class*="icos-"] { padding: 5px 0; margin: 0; }
.whead .dropdown-menu [class*="icos-"] { padding: 4px 0 0 0; margin-right: 10px; }
.whead .pull-right { right: -1px; }
.whead .bsideText { float: right; color: #217199; display: block; padding: 8px 14px 7px 14px; font-size: 12px; font-weight: bold; }
.whead .headInput { float: right; width: 50%; margin: 8px 7px 6px 7px; position: relative; }
.whead .headInput input[type=text] { width: 100%; box-sizing: border-box; font-size: 11px; color: #858585; box-shadow: 0 1px 0 #f5f5f5; padding: 4px 20px 5px 5px; border: 1px solid #d7d7d7; display: inline-block; background: #fdfdfd; -webkit-appearance: none; }
.widget .whead > .titleIcon { float: left; padding: 9px 0 10px 0; width: 40px; border-right: 1px solid #D5D5D5; text-align: center; }
.titleIcon .checker { margin: 2px auto 0 auto; float: none; }

/* === Buttons === */

.buttonS, .buttonM, .buttonL, .buttonH, [class*="tablectrl"] {
  background: @primary-600;
  border: 1px solid @primary-600;
  border-radius: 6px !important; // Something in Common.css that comes from PrimeVue resets border-radius to 0. This should be replaced by AcButton in the upcoming releases, hopefully.
  color: @color-white;
  display: inline-block;
  font-size: 14px;
  padding: 0.5875rem 1rem;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
}
.buttonS span, .buttonM span, .buttonL span, .buttonH span { text-shadow: 0 1px 0 #6f6f6f; margin-left: 10px; }
.buttonS { padding: 6px 14px; }
.buttonM { padding: 7px 15px; }
.buttonL { padding: 8px 18px; }
.buttonH { float: right; padding: 4px 12px 4px 12px; color: #fff!important; margin: 7px 10px 6px 10px; }

/* === Fix for visit dialog buttons === */
.visit-information .buttonS span {
  margin: 0;
}

.visit-information .buttonS span:last-child {
  margin-left: -1px;
}

.visit-information .buttonS {
  box-sizing: border-box;
  line-height: 1;
  padding: 0 6px;
}

.bDefault,
.dualBtn,
.bGreen,
.bRed,
.bBlue,
.bGreyish,
.bBlack,
.bLightBlue,
.bGold,
.bSea,
.bBrown {
  border-radius: 6px;
  font-size: 14px !important;
  font-weight: 400;
  min-height: 32px;
  min-height: 18px;
  padding: 6px 12px !important;
  text-align: center;
  text-shadow: none;
}

.bDefault,
.dualBtn,
.bGreyish,
.bBlack {
  background: @shade-200;
  color: @shade-900;
  border-color: @shade-200;
}
.bDefault,
.dualBtn,
.bGreyish,
.bBlack {
  &:hover {
    background: @shade-300;
    border-color: @shade-300;
  }
  &:active {
    background-color: #b3bec9;
    box-shadow: 0 0 0 .2rem @shade-300;
  }
}

.bGreen {
  border: 1px solid @success-600;
  background: @success-600;
  color: @color-white !important;
}
.bGreen:hover {
  background-color: #1a7a36;
}
.bGreen:active {
  background-color: #176c30;
  box-shadow: 0 0 0 .2rem @green-300;
}

.bRed {
  border: 1px solid @danger-600;
  background-color: @danger-600;
  color:white !important;
}
.bRed:hover {
  background-color: #cc2519;
}
.bRed:active {
    background-color: #b62116;
    box-shadow: 0 0 0 .2rem @danger-300;
}

.bBlue {
  border: 1px solid @primary-600;
  background-color: @primary-600;
  color:white !important;
}
.bBlue:hover {
  background-color: #1251de;
}
.bBlue:active {
  background-color: @primary-700;
  box-shadow: 0 0 0 .2rem @primary-300;
}

.bLightBlue,
.bSea {
  border: 1px solid @help-600;
  background: @help-600;
}
.bLightBlue,
.bSea {
  &:hover {
    background-color: #05a19c;
  }
}
.bLightBlue,
.bSea {
  &:active {
    background-color: #048f8a;
    box-shadow: 0 0 0 .2rem @help-300;
  }
}

.bGold,
.bBrown {
  border: 1px solid @warning-500;
  background: @warning-500;
  color: @shade-800;
}
.bGold,
.bBrown {
  &:hover {
    background-color: #ea9a00;
  }
}
.bGold,
.bBrown {
  &:active {
    background-color: #d08900;
    box-shadow: 0 0 0 .2rem @warning-300;
  }
}
/* === Widgets === */

/* === Grid === */

.fluid { width: 100%; box-sizing: border-box; }
.fluid:before, .fluid:after { display: table; content: ""; }
.fluid:after { clear: both; }
.fluid [class*="grid"]:not(table) { display: block;}
.fluid [class*="grid"] { width: 100%; position: relative; box-sizing: border-box; float: left; margin-left: 2.127659574%; }
.fluid [class*="grid"]:first-child { margin-left: 0; }

@media (min-width: 768px) {
    .fluid .grid12 { width: 99.99999998999999%; }
    .fluid .grid11 { width: 91.489361693%; }
    .fluid .grid10 { width: 82.97872339599999%; }
    .fluid .grid9 { width: 74.468085099%; }
    .fluid .grid8 { width: 65.95744680199999%; }
    .fluid .grid7 { width: 57.446808505%; }
    .fluid .grid6 { width: 48.93617020799999%; }
    .fluid .grid5 { width: 40.425531911%; }
    .fluid .grid4 { width: 31.914893614%; }
    .fluid .grid3 { width: 23.404255317%; }
    .fluid .grid2 { width: 14.89361702%; }
    .fluid .grid1 { width: 6.382978723%; }
}

/*  Loader top*/
.loading-global {
  background-color: #900;
  color: #FFFFFF;
  display: none;
  font-weight: bold;
  height: 25px;
  left: 50%;
  margin: 0 auto;
  padding-top: 5px;
  position: fixed;
  text-align: center;
  top: 15px;
  transform: translate(-50%, -50%);
  width: 150px;
  z-index: 10110;  /* Use same logic as ADS z-index: https://github.com/AlayaCare/alaya-design-system/blob/develop/src/tokens/zindex.yml */
}

/* === Tabs === */

/* Content tabs */

ul.tabs {
  height: 33px;
  font-size: 12px;
  font-weight: bold;
  color: #636363;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
ul.tabs li.activeTab:first-child { border-top-left-radius: 2px; }

.tab_container { overflow: hidden; width: 100%; }

ul.tabs li { float: left; height: 33px; line-height: 33px; border-left: none; overflow: hidden; position: relative; font-size: 15px; border-right: 1px solid #647382;  }
ul.tabs li a { display: block; padding: 0 16px; outline: none; color: #768da3; font-size: 13px; font-weight: normal; }
ul.tabs li a:hover { color: #797979; }
html ul.tabs li.activeTab  { background-color: #4f5f6f; height: 33px; }
html ul.tabs li.activeTab a { color: #fff; }

/* Sidebar tabs */

.tab { display: inline-block; background: #eee; border: solid 1px #999; border-bottom: none; border-radius: 4px 4px 0 0; }
.tab a { font-size: 14px; line-height: 2em; display: block; padding: 0 10px; outline: none; }
.tab a:hover { text-decoration: underline; }
.tab.active { background: #fff; padding-top: 6px; position: relative; top: 1px; border-color: #666; }
.tab a.active { font-weight: bold; }
.tab-container .panel-container { background: #fff; border: solid #666 1px; padding: 10px; border-radius: 0 4px 4px 4px; }

/* ===== Spinner ===== */

.ui-spinner { width: 10em; display: block; position: relative; overflow: hidden; border: 1px solid #d7d7d7; background: #fdfdfd; height: 26px; padding: 0 6px; box-shadow: 0 1px 0 #fff; }
.ui-spinner-disabled { background: #F4F4F4; color: #CCC; }
.ui-spinner input.ui-spinner-box { border: none!important; background: none!important; padding: 0!important; height: 26px; }
.ui-spinner-up, .ui-spinner-down { width: 18px; padding: 0; margin: 0; z-index: 100; position: absolute; right: 0; cursor: pointer; border: none; }
.ui-spinner-up { background: url('../../web/images/elements/forms/spinnerTop.png') no-repeat; height: 14px; top: -1px; }
.ui-spinner-down { height: 14px; bottom: -1px; background: url('../../web/images/elements/forms/spinnerBottom.png') no-repeat; }
.ui-spinner-list, .ui-spinner-listitem { margin: 0; padding: 0; font-size: 11px; }
.ui-spinner ul li, .ui-spinner-data { line-height: 26px; height: 26px; }

/* ===== Wizard ===== */

label.error { color: #A73939; font-size: 11px; display: block; width: 100%; white-space: nowrap; float: none; margin: 8px 0 -8px 0; }
.selector .error { margin-right: -220px; float: right; }
.checker label.error, .radio label.error { display: inline; }
.step h1 { font-weight: bold; font-size: 1em; position: absolute; top: -30px; right: 16px; }
.data span { font-size: 11px; text-align: center; border-top: 1px solid #DDD; padding: 12px 0; display: block; }

/* ===== Notification messages ===== */

.nNote { cursor: pointer; margin: 32px 0 0 0; border-radius: 2px; }
#sidebar .nNote { margin: 0; border-radius: 0; border-left: none; border-right: none; box-shadow: 0 1px 1px #f5f5f5; }
.nNote p, .dataTables_empty, .items .empty { padding: 10px 25px 10px 25px; margin: 0; text-align: center; font-weight: bold; }
.nMessage p { font-size: 11px; }
.nWarning{
  border: 1px solid #f2d083;
  color: #826200;
  box-shadow: 0 1px 2px #fff0c7 inset, 0 1px 1px #dfdfdf;
  background: #ffedbc;
  background: linear-gradient(to bottom,  #ffedbc 0%,#fbe5a8 100%);
}

.nSuccess {
  box-shadow: 0 1px 2px #e3f4bf inset, 0 1px 1px #dfdfdf;
  border: 1px solid #c1d779;
  color: #4f7307;
  background: #d6efa1;
  background: linear-gradient(to bottom,  #d6efa1 0%,#d0e69d 100%);
}

.nFailure {
  box-shadow: 0 1px 2px #fbb5b5 inset, 0 1px 1px #dfdfdf;
  border: 1px solid #dfa59b;
  color: #7d2a1c;
  background: #f0b5a1;
  background: linear-gradient(to bottom,  #f0b5a1 0%,#de9d8a 100%);
}
.nFailure > p { text-shadow: 0 1px 0 #DDD; }
.items .empty{display:block;}
.nInformation, .dataTables_empty, .items .empty  { padding:0; }

/* ========== Content pagination ========== */

.pagination { margin: 35px auto auto; width: auto; text-align: center; }
.pages li.prev { margin-right: 15px; }
.pages li.next { margin-left: 15px; }
.pages li.prev > a span, .pages li.next > a span { margin-right: 0; }
.pages li {  display: inline-block; }
.pages li a {
  background: #f9f9f9;
  background: linear-gradient(to bottom,  #f9f9f9 0%,#eeeeee 100%);
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  padding: 4px 7px;
  margin: 0 1px;
  cursor: pointer;
  color: #606060;
  font-size: 11px;
  box-shadow: 0 1px 0 #fff inset, 0 1px 0 #dfdfdf;
}
.pages li a:hover {
    background: #f6f6f6;
    background: linear-gradient(to bottom,  #f6f6f6 0%,#e8e8e8 100%);
}
.pages li .active, .pages li a:active, .pages li .active:hover {
  background: #6f6f6f;
  color: #fff;
  border-color: #646464;
  box-shadow: 0 1px 2px #545454 inset, 0 1px 0 #fff;
  font-weight: bold;
}

/* ===== Timepicker  ===== */

.timepicker { width: 65px!important; float: left; }
.timeEntry_control { margin-left: -1px; margin-top: 0; cursor: pointer; float: left; }
* html .timeEntry_control { margin-top: -4px; }
.timeRange li { float: left; }

/* ===== News widget  ===== */

.wNews { float: left; width: 80%; margin-top: 2px; }
.wNews span { display: block; line-height: 14px; margin-top: 4px; }
.wNews > .headline { float: left; margin-right: 14px; }
.wNews .announce > a { font-weight: bold; line-height: 18px; }

/* ===== Search widget  ===== */

.searchWidget .whead input[type=text] { border: 1px solid #cdcdcd; padding: 6px 25px 6px 8px; width: 100%; box-sizing: border-box; border-radius: 2px; box-shadow: 0 1px 1px #fcfcfc; }
.searchWidget .whead { padding: 11px; }
.searchWidget button { background: none; border: none; color: #909090; position: absolute; top: 12px; right: 17px; }
.searchWidget .pagination {  padding: 12px; border-top: 1px solid #cdcdcd; box-shadow: 0 1px 0 #fff inset; margin-top: 0;
    background: #f8f8f8;
    background: linear-gradient(to bottom,  #f8f8f8 0%,#e8e8e8 100%);
}

/* ===== Widget buttons  ===== */

.wButton a, .wButton input[type=button], .wButton button, .wButton input[type=button] { width: 100%; box-sizing: border-box; text-align: center; margin-top: 35px; }

/* ===== Settings widget  ===== */

.params li:last-child { border-bottom-right-radius: 2px; border-bottom-left-radius: 2px; }
.params li:first-child span { display: block; line-height: 20px; }
.params li:first-child a { font-size: 12px; color: #bbb; }
.params li > label { float: left; }
.params li h5 { font-size: 14px; font-weight: bold; text-shadow: 0 1px #f5f5f5; padding: 2px 0 6px 0; display: block; }
.params li h5 > a { display: inline-block; }

.formRow {
  border-bottom: 1px solid #DDD;
  border-top: 1px solid white;
  padding: 16px;
}

.formRow br {
  content: "";
  display: block;
  height: 1rem;
}

.formRow:first-child { border-top: none; }
.formRow input[type=text], .formRow input[type=password], textarea {
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  color: @shade-700;
  padding: 8px;
  border: 1px solid @shade-400;
  display: inline-block;
  background: @color-white;
}

.formRow span.crossClear {
  position:absolute;
  right:5px;
  top:0;
  bottom:0;
  height:14px;
  margin:auto;
  font-size:14px;
  cursor:pointer;
  color:#ccc;
}

textarea { height: auto; }

.formRow  [class*="grid"] > label {
  float: left;
  display: block;
  padding-top: 1px;
  padding-left:5px;
  color: @shade-600;
  font-size: 1rem;
}

.formRow label > img {
  float: left;
  margin: 5px 10px 0 0;
}

.req { float: right; margin-left: 5px; display: block; color: #DB6464; }

.note { font-size: 11px; padding-top: 2px; display: block; color: #AAA; white-space: nowrap; }
.formRow > label {
  padding: 6px 0;
  display: block;
  float: left;
}

.multiple { width: 100%; height: 200px; padding: 5px; border: 1px solid #d7d7d7; box-shadow: 0 1px 0 #fff; }

.formRow input[disabled] { opacity: .6; }
.formSubmit { display: block; float: right;  margin:5px;}
.hasIcon [class*="icon"] { float: none; margin-right: 0; }

/* ===== Form validation ===== */

.inputContainer { position: relative; float: left; }
.formError { position: absolute; top: 300px; left: 282px; display: block; z-index: 998; cursor: pointer; }
.ajaxSubmit { padding: 20px; background: #55ea55; border: 1px solid #999; display: none; }

.checker input, .radio input, .selector select { cursor: pointer; }
input.error { border-color: #DDBEBE!important; }

/* ===== Form styling  ===== */

.selector, .radio, .checker, .selector *, .radio *, .checker * { margin: 0; padding: 0; cursor: pointer; }

div.selector {
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--shade-400);
  border-radius: 6px;
  color: #4d586a;
  display: flex;
  position: relative;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;

  &:not(.disabled):hover {
    border-color: var(--blue-600);
  }

  &:not(.disabled):focus-within {
    border-color: var(--blue-600);
    box-shadow: 0 0 0 0.2rem var(--blue-300);
    outline: 0 none;
    outline-offset: 0;
  }

  &.disabled {
    opacity: .6;
    cursor: not-allowed;

    select {
      cursor: not-allowed;
      opacity: 0;
    }
  }

  &:after {
    color: var(--shade-600);
    content: "\f31a";
    font-family: "Phosphor";
    font-size: 16px;
    font-weight: 900;
    pointer-events: none;
    position: absolute;
    right: 9px;
    top: calc(50% - 8px);
  }

  span {
    background-color: @color-white;
    border-radius: 6px;
    box-sizing: border-box;
    color: @shade-700;
    cursor: pointer;
    display: block;
    font-size: @font-size-md;
    min-height: 36px;
    overflow: hidden;
    padding: 0.5rem calc(0.5rem + 30px) 0.5rem 0.5rem;
    text-overflow: ellipsis;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    white-space: nowrap;
  }

  select {
    font-size: 14px;
    height: 35px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 8px;
  }
}

.disabled { opacity: 0.6; }
.labelDisabled { color: #B7B7B7; }

.formRow div.selector {
    display: block;
    max-width: none;
}

div.period_chooser {
    line-height: 27px;
    box-sizing: content-box;
    border-radius: 2px;
    border: 1px solid #D2D2D2;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 2px 0;
    padding: 1px 0 0 8px;
    cursor: pointer;
    width: auto;
    font-size: 12px;
    position: relative;
    overflow: hidden;
}

// File input from UI.php that were previously skinned with jQuery Uniform
// Do not target ADS file input or webapp core components
input[type="file"].php-ui-file-input {
  padding-top: 2px;

  &::before {
    content: '';
    display: inline-block;
    height: 28px;
    vertical-align: middle;
  }
}

/* ===== Tags input  ===== */

div.tagsinput {
  border: 1px solid var(--shade-400);
  border-radius: 6px;
  background: @color-white;
  min-height: 38px;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

div.tagsinput span.tag {
  border-radius: 16px;
  color: var(--shade-700);
  display: block;
  float: left;
  padding: 0 8px;
  background: var(--shade-300);
  margin: 5px;
  line-height: 23px;
  font-size: 11px;
}

div.tagsinput span.tag > a {
  color: var(--shade-700);
  font-size: 11px;
  float: right;
}

div.tagsinput input { width: 60px; border: none; padding: 5px 8px 6px 8px; background: transparent; margin: 5px 5px 0 4px; }
div.tagsinput div { display: block; float: left; }
.tags_clear { clear: both; width: 100%; height: 0; }
.not_valid { background: #FBD8DB !important; color: #90111A !important;}

/* # Tables
================================================== */

.dataTables_length .selector { margin-top: 1px; }
table thead td.sortCol > div { cursor: pointer; position: relative; }
table thead td span { background: url('../../web/images/tables/sort.png') no-repeat 0; display: block; width: 7px; float: right; margin: 2px 2px 0 5px; height: 16px; }
table thead td.headerSortUp span { background: url('../../web/images/tables/sortUp.png') no-repeat 0; }
table thead td.headerSortDown span { background: url('../../web/images/tables/sortDown.png') no-repeat 0; }

.checkAll tbody tr td:first-child { width: 40px; padding: 0; vertical-align: middle; margin: 0;}
.checkAll tbody tr td:first-child .checker, .checkAll tbody tr td:first-child .radio { margin: 0 auto; float: none; }

/* ===== Default table ===== */

.tDefault tbody td, .tDefault thead td { border-left: 1px solid #DFDFDF; box-shadow: 0 1px 0 #fafafa inset; }
.tDefault tbody td:first-child, .tDefault thead td:first-child { border-left: none; }
.checkAll thead td:first-child > img { vertical-align: middle; padding-bottom: 2px; }
.tDefault thead td { text-align: center; font-size: 11px; padding: 3px 5px 2px 5px; color: #909090; background: #eee; }
.tDefault tbody td { padding: 7px 11px; vertical-align: middle; }
.tDefault tbody tr { border-top: 1px solid #DFDFDF; }
.tDefault tbody tr:first-child { box-shadow: 0 1px 0 #fff inset; }
.tDefault tbody tr:nth-child(even) { background: #f2f2f2; }

/* ===== Table toolbar ===== */

.tToolbar {
    padding: @space-lg;
    display: flex;
    gap: 8px;
}

.tToolbar > ul {
  display: contents;
}

.tToolbar li {
    display: block;
}

.tToolbar .dropdown-menu li{
    float:none;
}

.tToolbar li a {
    padding: @space-md @space-xxl;
    border-radius: 6px;
    color: @shade-700;
    font-weight: var(--font-weight-bold);
    font-size: @font-size-sm;
    display: flex;
    align-items: center;
}

.tToolbar li a:hover {
    background: @shade-200;
}

.tToolbar li a:active { background: #f8f8f8; }

.tablectrl_small [class^="icon-"],
.tablectrl_small [class^="icon-"],
.tToolbar [class^="icon-"],
.tToolbar [class*=" icon-"] {
  line-height: 20px;
}

/* ===== Table buttons ===== */

[class*="tablectrl"] { text-shadow: none; }
[class*="tablectrl"] .caret { margin: 5px 0 0 6px; }

.tablectrl_small {
  font-size: 11px;
  padding: 3px 5px 2px 5px;
}

.tablectrl_medium { font-size: 12px; padding: 6px 6px 5px 6px; }
.tablectrl_large { font-size: 14px; padding: 7px 7px 6px 7px; }
.tablectrl_xlarge { font-size: 16px; padding: 10px 12px 11px 12px; }
.tablectrl_standard { font-size: 12px; padding: 8px; }

.opt { padding-left: 8px; padding-right: 8px; margin: 0; }
.opt > span { margin-left: 0; }
.opt .iconb { font-size: 14px; }
.opt .caret { margin: 5px 0; }

/* # Login page
================================================== */

.loginWrapper {
  width: 320px;
  margin: auto;
  text-align: center;
  position:relative;
}

/* === Login hover buttons === */
#login {
  align-items: center;
  display: flex;
  min-height: 100%;
  padding: 30px 0; /* Add some vertical space in mobile so form is not directly at top of page */
  box-sizing: border-box;
}
.loginWrapper form { width:100%; height:100%; position:absolute; top:0; left:0; }

.loginPic { text-align: center; display: block; position: relative; overflow: hidden; }
.loginWrapper form {
  display: block; margin: 0 auto; width: 236px;
}
.loginWrapper input[type=text],
.loginWrapper input[type=password],
.loginWrapper input[type=email] {
  color: @shade-700;
  // background: #f9fafb;
  border: 1px solid #ced2da !important;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  height: auto;
  margin: 15px 0 0;
  padding: 0.5rem 0.5rem;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s,-webkit-box-shadow .2s;
  width: 100%;
  -webkit-transition: background-color .2s,color .2s,border-color .2s,-webkit-box-shadow .2s;
}

.loginWrapper input[type=text]:focus,
.loginWrapper input[type=password]:focus,
.loginWrapper input[type=email]:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #a0bbf7;
  box-shadow: 0 0 0 0.2rem #a0bbf7;
  border-color: #1d5eed;
}

.logControl { margin-top: 20px; }
.logControl .memory { float: left; margin-top: 5px; }
.logControl input[type=submit] { float: right; }

.loginPanel {
  background-color: @color-white !important;
  border-color: #EFF2F5 !important;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.34901960);
  min-width: 260px;
  padding: 20px 16px 50px 16px ;
}

/* Messages page
================================================== */

.messagesOne li { position: relative; min-height: 36px; margin: 0 18px 20px 18px; }
.messagesOne li:first-child { margin-top: 20px; }
.messagesOne > li.divider { border-top: 1px solid #DFDFDF!important; margin: 20px 0 20px 0!important; min-height: 0; }
.messagesOne > li.divider > span { background-color: #f5f5f5!important; }
.messagesOne .messageRow a { display: block; width: 37px; height: 36px; }

.messagesOne .by_user > a, .messagesOne .by_me > a  { position: absolute; top: 0; display: block; }
.messagesOne .by_user > a { left: 0; }
.messagesOne .by_me > a { right: 0; }

.messagesOne .by_user .aro, .messagesOne .by_me .aro { width: 8px; height: 9px;  position: absolute;  top: 12px; }
.messagesOne .by_user .aro { background: url('../../web/images/elements/other/messageArrow_left.png') no-repeat; left: -8px; }
.messagesOne .by_me .aro { background: url('../../web/images/elements/other/messageArrow_right.png') no-repeat; right: -8px; }

.messagesOne .by_user .messageArea, .messagesOne .by_me .messageArea { box-sizing: border-box; padding: 8px 12px; position: relative; border-radius: 3px; border: 1px solid; }
.messagesOne .by_user .messageArea { background: #fafeff; border-color: #b5cdd7; margin-left: 60px; }
.messagesOne .by_me .messageArea { background: #fefefe; border-color: #dcdcdc; margin-right: 60px; }

.by_user .name > strong { color: #2c596d; }
.by_me .name > strong { color: #393939; }

.messagesTwo li { position: relative; min-height: 36px; border-top: 1px solid #cdcdcd; }
.messagesTwo li:first-child { border-top: none; }
.messagesTwo li > a { margin: 14px; display: block; position: absolute; left: 0; top: 0; }
.messagesTwo li .messageArea {
    box-sizing: border-box;
    padding: 5px;
    position: relative;
    border-left: 1px solid #cdcdcd;
    margin-left: 55px;
}
.messagesTwo .by_user { background: #FEFEFE; }

/* # Special classes for resizable grid. Class "under" added dynamically in function.js
================================================== */

.under .fluid [class*="grid"] { width: 100%; margin-left: 0; }
.under .widget [class*="grid"] { display: block; width: 100%; position: relative; box-sizing: border-box; float: left; margin-left: 2.127659574%; }
.under .widget [class*="grid"]:first-child { margin-left: 0; }
.under .widget .grid12 { width: 99.99999998999999%; }
.under .widget .grid11 { width: 91.489361693%; }
.under .widget .grid10 { width: 82.97872339599999%; }
.under .widget .grid9 { width: 74.468085099%; }
.under .widget .grid8 { width: 65.95744680199999%; }
.under .widget .grid7 { width: 57.446808505%; }
.under .widget .grid6 { width: 48.93617020799999%; }
.under .widget .grid5 { width: 40.425531911%; }
.under .widget .grid4 { width: 31.914893614%; }
.under .widget .grid3 { width: 23.404255317%; }
.under .widget .grid2 { width: 14.89361702%; }
.under .widget .grid1 { width: 6.382978723%; }

.dialog .elastic-list-container .fluid .elastic-list,  .dialog .elastic-list-container .fluid .div-tab-col-content {
    width: 100%;
    margin-left: 0;
}

.legacy-content .legacy-sidebar {
  padding: 8px 0;
}

/* Elastic side menu that becomes a dropdown (FlexTabWidget.php)  */
.elastic-list .caret {display:none;}
.elastic-list .elastic-list-selected-label{display:none;}

.under .elastic-list .elastic-list-menu-label, .dialog .elastic-list .elastic-list-menu-label{display:none;}
.under .elastic-list .elastic-list-selected-label,.dialog .elastic-list .elastic-list-selected-label{display:inline;}
.under .elastic-list .whead h6, .dialog .elastic-list .whead h6{ color: black; float:none;}
.under .elastic-list .caret, .dialog .elastic-list .caret{display:inline; float:right; text-align: right; }
.under .elastic-list ul, .dialog .elastic-list ul{
    display:none;
    position: absolute;
    top: 42px;
    border:1px solid @shade-300;
    z-index:1200;
    width: 100%;
    padding:0;
    margin-left:-1px;
    left: 0;
    text-align: left;
}

.under .verticalTab > li.active,.dialog .verticalTab > li.active {background:#ddd;}
.under .elastic-list ul.open,.dialog .elastic-list ul.open{display:block; }

/*  Hide the faculative rows when we are in smaller mode
Left menus = 326
*/
.under .facultative{
    display:none;
}

/* Styling for the right panel*/
.layout-right-panel {
    display:none;
    background:#eee; position:absolute; top:49px; border-left:1px solid #C3C3C3; right:-235px; height: 100%; width:260px;
}

.mobileOnly{display:none;}
.smallScreenOnly{display:none;}
.largeScreenOnly{display:block;}

.main-content-center{
    margin-right:0;
}

.side_panel_show{display:none;}

@media only screen and (max-width: 1400px){
    .side_panel_hidden .side_panel_show{
        z-index: 12000;
        position: absolute;
        top: 65px;
        right: 0;
        display:block;
        text-align: right;
        width:100px;
    }
    .smallScreenOnly{ display:inherit;}

    .layout-right-panel{
        display:none;
        position:absolute;
        right:0;
        top: 0;
        z-index: 12000;
        box-shadow: -5px 0 5px rgba(50, 50, 50, 0.5);
    }
    .layout-right-panel-close{
        display:block;
    }

    .layout-right-panel.panel_on{
        display:block;
    }
}

@media only screen and (max-width: 480px){
    .side_panel_hidden .side_panel_show{display:none; }
    .mobileOnly{ display:inherit;}

    .smallScreenOnly{display:none;}
    .layout-right-panel{
        display:none;
        position:absolute;
        right:0;
        width:100%;
        z-index: 12000;
        top:50px;
    }
    .layout-right-panel-close{
        display:none;
    }
}

/* # Special classes for responsive layout
================================================== */

/*   Icon for backup only */
ul.backupicon li a span{display:none;}
ul.backupicon  li.liOverflowed  a span{display:block;}

.tabs.overflowed li a { width: 14px; }
.tabs.overflowed li a span { text-indent:0; margin-right: 0; }

/** Tabs**/
li.liOverflowed{overflow: hidden;}

.tabs  li.liOverflowed a span { text-align:center; text-indent:0; margin-right: 0; display: block; margin-top:12px; margin-bottom: 20px; }

/* Toolbar */
.dataTable .listview-column{display:none;}

/****  When the dual is in list_only ***/
.dual_list .dual .dataTable thead tr th{display:none;}
.dual_list .dual .dataTable tbody tr td{display:none; padding:0; margin:0;border:0;}
.dual_list .dual .dataTable tbody tr {display:block;}
.dual_list .dual .dataTable tbody tr td.listview-column{display:block;}
.dual_list .dual .dataTables_info{display:none;}

.dual_list .dual .dataTable tbody tr:first-child{
    border-top: 1px solid #ccc;
}
.dual_list .dual .dataTable tbody tr:hover{background:none;}
.dual_list .dual .dataTable tbody tr{
    padding: 8px 24px 8px 12px;
    border-bottom: 1px solid @shade-200;
    border-top: 1px solid @shade-200;
    position: relative;
    background: @white;
}

/****  When the dual is in the side widget ***/
.layout-right-panel .dual .dataTable thead tr th{display:none;}
.layout-right-panel .dual .dataTable tbody tr td{display:none; padding:0; margin:0;border:0;}
.layout-right-panel .dual .dataTable tbody tr {display:block;}
.layout-right-panel .dual .dataTable tbody tr td.listview-column{display:block;}
.layout-right-panel .dual .dataTables_info{display:none;}

.layout-right-panel .dual .dataTable tbody tr:first-child{
    border-top: 1px solid #ccc;
}
.layout-right-panel .dual .dataTable tbody tr:hover{background:none;}
.layout-right-panel .dual .dataTable tbody tr{
    padding: 8px 24px 8px 12px;
    border-bottom: 1px solid @shade-200;
    border-top: 1px solid @shade-200;
    position: relative;
    background: @white;
}

/* # Media queries
================================================== */

@media only screen and (max-width: 1350px) {
  body { -webkit-text-size-adjust: none; }
  .fluid .grid12 { width: 99.99999998999999%; }
}

@media only screen and (max-width: 1024px) {
    input[type="text"],input[type="password"], textarea { -webkit-appearance: none; }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
    .under .widget .grid4.river{
        width: 48.93617020799999%;
    }
}

@media only screen and (min-width: 1250px){
    .widget .grid4.river{
        width: 23.404255317%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    /** River items**/
    #content { margin-left: 100px; }
    .subNav li a.this { margin-right: 0; }
    #sidebar [class*="dataNum"] { display: inline-block; float: right; margin-left: 14px; position: static; margin-top: 1px; }
}

#content { margin-left: 100px; }
.subNav li a.this { margin-right: 0; }
#sidebar [class*="dataNum"] { display: inline-block; float: right; margin-left: 14px; position: static; margin-top: 1px; }

@media only screen and (min-width: 300px) and (max-width: 1037px){
    .type-Icon{
        display: block;
        height:95px;
        float:left;
        position:static!important;
        border:0 solid #000;
        text-align: center;
        cursor:pointer;
        width: 31.914893614%;
    }
}

/*  Dashboard*/
.nav li:hover ul li a span { font-size:14px;}

@media only screen and (min-width: 769px) and (max-width: 980px) {
    .contentTop { overflow: hidden; }
}

/* ---------------- MOBILE DESIGN ---------------- */
/* Breakpoint need to be in sync with @breakpoint-md from ~@alayacare/alaya-design-system/dist/system/tokens/tokens.less'; */
@media only screen and (max-width: 800px) {
    .nav { margin-top: 0; display: none; }
    .nav li { display: inline-block; }
    .nav li a { padding: 14px; }
    .nav li a span { display: none; }
    .nav li ul { top: 53px; padding: 0; right: 0; left: 0; }
    .nav li ul li { display: block; }
    #content { margin: 0!important; }
    .middleNavA li { width: 110px; }
    .middleNavA li a { white-space: normal; }
    .checker, .radio { clear: both; }
    .user { padding: 16px 14px; text-align: left; box-sizing: border-box; display: block; float: left; width: auto; border: none; }
    .user > span { display: none; }
    .under .widget [class*="grid"] { width: 100%; margin: 5px 0; }
    .formRow [class*="grid"]:first-child label { float: none; display: inline-block; margin-top: -8px; margin-bottom: 0; padding: 0; }
    .formRow [class*="grid"] label { float: left; }
    .under .wrapper { margin: 0 0; }
    .bc { display: none; }

    /* Responsive tables */

    .tDefault { display: block; position: relative; width: 100%; }
    .tDefault thead tr td, .tDefault tbody tr td { border-top: 1px solid #dfdfdf; }
    .tDefault thead { display: block; float: left; }
    .tDefault thead tr { border-right: 1px solid #dfdfdf; }
    .tDefault thead td { box-shadow: none; border: none; padding: 7px 11px; }
    .tDefault tbody { display: block; width: auto; position: relative; overflow-x: auto; overflow-y: scroll; white-space: nowrap; }
    .tDefault tbody tr td { border-left: none; min-width: 80px; }
    .tDefault tbody td:first-child { border-top: none; }
    .tDefault tbody tr { border-top: none; }
    .tDefault thead td:first-child { border-left: none; border-top: none; }
    .tDefault thead tr { display: block; }
    .tDefault td { display: block; }
    .tDefault tbody tr { display: inline-block; vertical-align: top; margin-left: -3px; border-right: 1px solid #dfdfdf; }
    .tDefault tbody tr:first-child { margin-left: 0; }
    .tDefault td { display: block; }

    .checkAll thead tr td:first-child, .checkAll tbody tr td:first-child { padding: 7px 11px; }
    .checkAll tbody tr td { min-width: 80px; }
    .checkAll thead td:first-child > img { vertical-align: middle; padding-top: 4px; padding-bottom: 2px; }

    .dataTable { display: block; position: relative; width: 100%; }
    .dataTable thead tr th,
    .dataTable tbody tr td {
      align-items: center;
      border-top: 1px solid #dfdfdf;
      display: flex;
      justify-content: center;

      &.link-column {
        height: 35px;
      }

      & > a {
        width: 100%;
      }

      .label-info {
        width: 100%;
      }
    }
    .dataTable thead { display: block; float: left; }
    .dataTable thead tr { border-right: none; }
    .dataTable thead th { box-shadow: none!important; border-left: none!important; padding: 7px 11px!important; }
    .dataTable tbody { display: block; width: auto; position: relative; overflow-x: auto; white-space: nowrap; }
    .dataTable tbody tr td { border-left: none; }
    .dataTable tbody td:first-child { border-top: none; }
    .dataTable tbody tr { border-top: none; border-left: 1px solid #dfdfdf; }
    .dataTable tbody tr:first-child { border-left: none; }
    .dataTable thead th:first-child { border-left: none; border-top: none; }
    .dataTable thead tr { display: block; border-right: 1px solid #dfdfdf; }
    .dataTable td { display: block; vertical-align: top; }
    .dataTable tbody tr { display: inline-block; vertical-align: top; border-left: 1px solid #dfdfdf; }
    .dataTable tbody tr:first-child { margin-left: 0; }
    .dataTable td { display: block; }
    .sorting, .sorting_asc, .sorting_desc { background-image: none; }
    .dataTables_filter label > span, .showentries { display: none; }
    .dataTables_filter input[type="text"] { margin-left: 0; width: 130px; margin-top: -1px; }
    .dataTables_info { display: none; }
    .dataTables_paginate { float: none; }
    .dataTables_paginate .previous, .dataTables_paginate .next { display: none; }
    .fg-toolbar { text-align: center; }

    table.dataTable tbody tr td { border-left: none; }

    .dual .dataTable thead tr th{display:none;}
    .dual .dataTable tbody tr td{padding:0; margin:0;border:0;}
    .dual .dataTable tbody tr {display:block;}
    .dual .dataTable tbody tr td.listview-column{display:block;}
    .dual .dataTable tbody tr td.tb{display:block;}
    .dual .dataTable tbody tr td.dataTables_empty{display:block; padding:10px; margin:0;}
    .dual .dataTables_info{display:none;}

    .dual .dataTable tbody tr:first-child{
        border-top: 1px solid #ccc;
    }
    .dual .dataTable tbody tr:hover{background:none;}
    .dual .dataTable tbody tr{
        padding: 8px 24px 8px 12px;
        border-bottom: 1px solid @shade-200;
        border-top: 1px solid @shade-200;
        position: relative;
        background: @white;
    }

    .ui-dialog { max-width: calc(100% - 32px); }
    table .dropdown-menu.pull-right { right: auto; left: 0; }
    .step h1 { display: none; }
    .logControl { padding-bottom: 50px; }
}

/*
Addons and modification to the framework
**/
.river,.river_item{ line-height: 12px;
    display: block;
    padding: 8px 24px 8px 12px;
    font-size: 11px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #fdfdfd;
    position: relative;
    background: #fafafa;
    background: linear-gradient(to bottom, #fafafa 0%,#eeeeee 100%);
}

.contentTop {
  background-color: var(--surface-f , #f9fafb);
  color: var(--text-color, #4d586a);
}

.contentTop {
  .acwa-tabs-header();
}

.contentTop.new-legacy-header {
  align-items: center;
  background-color: var(--color-white, #fff);
  color: var(--shade-900, #181b21);
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  height: auto;
  padding: .5rem 1rem;
}

.contentTop.new-legacy-header .pageTitle {
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.778;
  margin: 0;
}

.tabs__content .contentTop.care-33-employees-routing {
  display: none;
}

ul.tabs li {
    float: left;
    height: 43px;
    line-height: 42px;
    border-left: none;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    border-right: 1px solid #647382;
}
ul.tabs {
    height:43px;
}

html ul.tabs li.activeTab {
    background-color: #4f5f6f;
    height: 43px;
}

.tToolbar .liOverflowed {
    overflow: auto;
}

.tToolbar .liOverflowed a {
    align-items: center;
}

.subNav li a {
    color: #6A6A6A;
    padding: 4px 8px 4px 8px;
    font-size:12px;
    font-weight: normal;
}

.subNav li a.header{
    padding-left:25px;
}

/* = STEPS CONTAINER (FlexWizardSteps in StartController)
----------------------------*/
.wizard-steps {
    margin:20px 10px 0 10px;
    padding:0;
    position: relative;
    clear:both;
    font-weight: bold;
}
.wizard-steps div {
    position:relative;
}
/* = STEP NUMBERS
----------------------------*/
.wizard-steps span {
    display: block;
    float: left;
    font-size: 12px;
    text-align:center;
    width:15px;
    margin: 2px 5px 0 0;
    line-height:15px;
    color: #ccc;
    background: #FFF;
    border: 2px solid #CCC;
    border-radius:10px;
}
/* = DEFAULT STEPS
----------------------------*/
.wizard-steps a {
    position:relative;
    display:block;
    width:auto;
    height:24px;
    margin-right: 18px;
    padding: 0 10px 0 3px;
    float: left;
    font-size:12px;
    line-height:24px;
    color:#666;
    background: #FFF;
    text-decoration:none;
    text-shadow:1px 1px 1px rgba(255,255,255, 0.8);
}
.wizard-steps a:before {
    width:0;
    height:0;
    border-top: 12px solid #FFF;
    border-bottom: 12px solid #FFF;
    border-left:12px solid transparent;
    position: absolute;
    content: "";
    top: 0;
    left: -12px;
}
.wizard-steps a:after {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left:12px solid #FFF;
    position: absolute;
    content: "";
    top: 0;
    right: -12px;
}

/* = COMPLETED STEPS
----------------------------*/

.wizard-steps .completed-step a {
    color:#163038;
    background: #A3C1C9;
}
.wizard-steps .completed-step a:before {
    border-top: 12px solid #A3C1C9;
    border-bottom: 12px solid #A3C1C9;
}
.wizard-steps .completed-step a:after {
    border-left: 12px solid #A3C1C9;
}
.wizard-steps .completed-step span {
    border: 2px solid #163038;
    color: #163038;
    text-shadow:none;
}
/* = ACTIVE STEPS
----------------------------*/
.wizard-steps .active-step a {
    color:#A3C1C9;
    background: #163038;
    text-shadow:1px 1px 1px rgba(0,0,0, 0.8);
}
.wizard-steps .active-step a:before {
    border-top: 12px solid #163038;
    border-bottom: 12px solid #163038;
}
.wizard-steps .active-step a:after {
    border-left: 12px solid #163038;
}
.wizard-steps .active-step span {
    color: #163038;
    box-shadow: 0 0 2px rgba(0,0,0, 0.8);
    text-shadow:none;
    border: 2px solid #A3C1C9;
}
/* = HOVER STATES
----------------------------*/
.wizard-steps .completed-step:hover a, .wizard-steps .active-step:hover a {
    color:#fff;
    background: #8F061E;
    text-shadow:1px 1px 1px rgba(0,0,0, 0.8);
}
.wizard-steps .completed-step:hover span, .wizard-steps .active-step:hover span {
    color:#8F061E;
}
.wizard-steps .completed-step:hover a:before, .wizard-steps .active-step:hover a:before {
    border-top: 12px solid #8F061E;
    border-bottom: 12px solid #8F061E;
}
.wizard-steps .completed-step:hover a:after, .wizard-steps .active-step:hover a:after {
    border-left: 12px solid #8F061E;
}

/* Staff / Client / Employee overview */
.photo_display{
    border-radius:4px;
    height: 75px !important;
    width: 75px !important;
}

.tabs_container.subtabs > .widget > .whead{
    border: 1px solid #68a341;
    box-shadow: 0 1px 2px 0 #a4ca6c inset;
    background: #96c161;
    background: linear-gradient(to bottom, #96c161 0%,#609c3d 100%);
}

ul.tabs.subtabs li a{
    color:#fff;
    text-shadow: 1px 1px #609c3d;
    box-shadow: none;
}

html ul.tabs.subtabs li.activeTab{
    background:#96c161;
    height:43px;
}
html ul.tabs.subtabs li.activeTab a{
    color:#fff;
    text-shadow: none;
}

html ul.tabs.subtabs.whead{
  border-radius: 3px;
  box-shadow: 3px 2px 1px #000;
}

.dTable td div.selector{width:95%;}

/* Vertical tabs in visit dialog */
.pop-side-menu {
    cursor: pointer;
    min-height: 20px;
    border-right: 1px dashed #eee;
    color: #636363;
    background: #f2f2f2;
    background: linear-gradient(to bottom, #f2f2f2 0%,#e8e8e8 100%);
}
.pop-side-menu.selected {
    background: none;
    border-left: 5px solid #0e509e;
    color: #111;
}
.pop-side-menu a {
    padding: 15px;
    display: block;
}

.pop-side-menu a span.fa {
    margin-right: 5px;
}

div.fluid.menu_window {
    display: flex;
    min-height: 100%;
}

div.fluid.menu_window > div {
    align-items: stretch;
}

.blurred{
    font-size:11px; color:#ccc;
}

.ui-dialog .formRow{
    border-top:0;
}

#top .dataNumRed{display:inline; box-shadow: none; background: #990000}

.logo img {
    margin: 0 0 0 12px;
}

.center {
    text-align: center;
}

.right {
    float:right;
}

/* Specific rule to display the services list filters on 2 lines */

form.filter-form {
    vertical-align: top;
    overflow: visible;
    display: flex;
    gap: 8px;
    align-items: start;
    flex-wrap: wrap;
}

form.filter-form div.selector,
form.filter-form input {
    vertical-align: top;
}

form#filter-form input[type="button"] {
    max-height: 28px;
}

form#ServiceList_form::after{
    content: '';
    width: 100%;
}

/* Horizontal TODO delete when no tabs in PHP anymore, don't forget green tabs of Client and grey tabs in Billing Period. */
html .pageNav ul.tabs { height: auto; font-size: 12px; font-weight: bold; color: #636363; border-radius: 0; }
html .pageNav ul.tabs li a { padding: 13px 15px; float: none; line-height: 1; font-size: 14px; }
html .pageNav ul.tabs li.liOverflowed a { height: 14px; }
html .pageNav ul.tabs li.activeTab  { height: auto; border-bottom-color: transparent; cursor: default; }
html .pageNav ul.tabs li {
    height: auto;
    line-height: 1;
    border: 1px solid transparent;
    border-right: 1px solid #f0f3f6;
}
html .pageNav ul.tabs {
    height: auto;
}
html .pageNav ul.tabs:before {
    content: " ";
    display: table;
}
html .pageNav ul.tabs:after {
    content: " ";
    display: table;
    clear: both;
}

/* Edit visit dialog replace */
.schedule-replacement-list-table th {
  padding-bottom: 5px;
}

.schedule-replacement-list-table td {
  padding-bottom: 5px;
  vertical-align: middle;
}

.schedule-replacement-list-table td input {
  margin-top: 0;
}

/* Fix wrong white background on old Bootstrap modal.
 * Target .modal to make sure we target Bootstrap modals.
 * This is happening because of libraries concurrency
 * Google Chart applies a white background on all .modal-dialog.
 * Since it is loaded after Bootstrap, it override the transparent background
 */
.modal .modal-dialog {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.pageTitle {
    display: flex;
    align-items: center;
}

.Attendance_form {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .filter_period_day.icon-calender {
    align-items: center;
    display: flex;
  }
}

.select2-selection__rendered {
  padding-left: 8px !important;
}

.p-toast .p-toast-detail {
  white-space: pre-line;
}

input.p-inputtext {
  /* to match ADS Styling */
  font-size: 1rem;
}

.widget-v2:not(.care-1-php-primary-buttons) > div {
  margin-top: @space-lg;
  padding-bottom: 0;
  padding-top: 0;
}

.widget-v2:not(.care-1-php-primary-buttons) .tToolbar > ul > li > a {
  background: @shade-200;
  border: 1px solid @shade-200;
  color: @shade-900;
  font-weight: 400;
  min-height: 18px;
  padding: 9px 12px !important;
}
.widget-v2:not(.care-1-php-primary-buttons) .tToolbar > ul > li:not(.disabled) > a:hover {
  background: @shade-300;
  border-color: @shade-300;
}
.widget-v2:not(.care-1-php-primary-buttons) .tToolbar > ul > li.disabled > a {
  cursor: default;
}

// #main_wrapper is only used for legacy-content

#main_wrapper {
  padding: 16px;
}

.tabs__content #main_wrapper {
  padding: 0;
}
